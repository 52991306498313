import { ClubDiverCertificationLabels } from '@mabadive/app-common-model';
import {
  dateService,
  diverCertificationFormatter,
  nameFormatter,
  phoneLinkBuilder,
  smsLinkBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { PRO_BookingResume } from '../../DiverBookingPage/models';
import { DiveCenterBookingMemberCertification } from './DiveCenterBookingMemberCertification';
import { DiveCenterBookingMembersListDiffItemCardDetails } from './DiveCenterBookingMembersListDiffItemCardDetails';
import { DiveCenterBookingMemberDiff } from './model';

export function DiveCenterBookingMembersListDiffItemCard({
  aggregatedBookingResume,
  memberDiff,
  className = '',
}: {
  aggregatedBookingResume: PRO_BookingResume;
  memberDiff: DiveCenterBookingMemberDiff;
  className?: string;
}) {
  const bookingMemberFullOriginal = memberDiff.original;
  const bookingMemberFull = memberDiff.final;
  const diver = bookingMemberFull?.diver;
  const diverOriginal = bookingMemberFullOriginal?.diver;
  const age = useMemo(
    () => (diver.birthdate ? dateService.getAge(diver.birthdate) : diver.age),
    [diver.age, diver.birthdate],
  );
  const ageOriginal = useMemo(
    () => (diver.birthdate ? dateService.getAge(diver.birthdate) : diver.age),
    [diver.age, diver.birthdate],
  );

  const certificationLabels: ClubDiverCertificationLabels = useMemo(
    () =>
      diverCertificationFormatter.formatCertificationLabels({
        diver,
        format: 'short',
      }),
    [diver],
  );
  const certificationLabelsOriginal: ClubDiverCertificationLabels = useMemo(
    () =>
      diverCertificationFormatter.formatCertificationLabels({
        diver: diverOriginal,
        format: 'short',
      }),
    [diverOriginal],
  );
  const isCertificationLabelsUpdated = useMemo(
    () =>
      certificationLabels?.gazCertificationLabel !==
        certificationLabelsOriginal?.gazCertificationLabel ||
      certificationLabels?.mainCertificationLabel !==
        certificationLabelsOriginal?.mainCertificationLabel ||
      certificationLabels?.orgReference !==
        certificationLabelsOriginal?.orgReference ||
      certificationLabels?.hasCertification !==
        certificationLabelsOriginal?.hasCertification,
    [
      certificationLabels?.gazCertificationLabel,
      certificationLabels?.hasCertification,
      certificationLabels?.mainCertificationLabel,
      certificationLabels?.orgReference,
      certificationLabelsOriginal?.gazCertificationLabel,
      certificationLabelsOriginal?.hasCertification,
      certificationLabelsOriginal?.mainCertificationLabel,
      certificationLabelsOriginal?.orgReference,
    ],
  );
  const phoneNumberClean = useMemo(
    () => smsLinkBuilder.cleanPhoneNumber(diver?.phoneNumber),
    [diver?.phoneNumber],
  );
  const emailAddress = useMemo(
    () => diver?.emailAddress?.trim(),
    [diver?.emailAddress],
  );

  const unchangedClass = 'grayscale opacity-80';

  const isUniqueMember = aggregatedBookingResume.bookingMembers.length === 1;

  return !bookingMemberFull ? null : (
    <div className={className}>
      <div className="w-full flex gap-2 items-center justify-between">
        <div className="w-full flex gap-2 justify-start items-center">
          <div
            className={`flex flex-col justify-center text-left ${className}`}
          >
            <p
              className={`leading-4 font-bold text-sm  text-app-blue whitespace-nowrap ${
                bookingMemberFull?.bookingMember?.details?.creationStatus ===
                'draft'
                  ? 'italic'
                  : ''
              }`}
            >
              <span
                className={clsx(
                  diver.firstName === diverOriginal.firstName &&
                    diver.lastName === diverOriginal.lastName &&
                    unchangedClass,
                )}
              >
                {nameFormatter.formatFullName(diver, {
                  format: 'firstName-first',
                })}
              </span>
              {age > 0 && (
                <span
                  className={clsx(
                    'font-bold text-app-primary',
                    ageOriginal === age && unchangedClass,
                  )}
                >
                  {' '}
                  {age} ans
                </span>
              )}
              {!!(diver.birthdate || diverOriginal.birthdate) &&
                diver.birthdate !== diverOriginal.birthdate && (
                  <span
                    className={clsx(
                      'font-bold',
                      !diver.birthdate
                        ? 'line-through text-status-error' // donnée supprimée!
                        : 'text-app-primary',
                    )}
                  >
                    {' '}
                    (
                    {dateService.formatUTC(
                      diver.birthdate ?? diverOriginal.birthdate,
                      'DD/MM/YYYY',
                    )}
                    )
                  </span>
                )}
            </p>
            <p className="leading-4 flex gap-2">
              {certificationLabels.hasCertification && (
                <span
                  className={clsx(
                    'uppercase text-gray-600 font-bold',
                    !isCertificationLabelsUpdated && unchangedClass,
                  )}
                >
                  <DiveCenterBookingMemberCertification
                    certificationLabels={certificationLabels}
                  />
                </span>
              )}
              {diver._id === bookingMemberFull.booking.bookingContactDiverId &&
                !isUniqueMember && (
                  <span className="uppercase px-0.5 text-app-orange font-medium text-app-xxs">
                    contact résa
                  </span>
                )}
            </p>
          </div>
        </div>
        <div className="flex-0 flex gap-2">
          {(phoneNumberClean || emailAddress) && (
            <div className="hidden md:flex gap-2">
              {phoneNumberClean && (
                <AppButton
                  fullWidth={false}
                  className="whitespace-nowrap"
                  icon={AppHeroIcons.phone}
                  color={'phone-outline'}
                  href={`tel:${phoneNumberClean}`}
                  // size="small"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span className="">
                    {phoneLinkBuilder.formatPhoneNumber(phoneNumberClean)}
                  </span>
                </AppButton>
              )}
              {emailAddress && (
                <AppButton
                  className="whitespace-nowrap truncate"
                  color={'mail-outline'}
                  icon={AppHeroIcons.contact}
                  href={`mailto:${emailAddress}`}
                  // size="small"
                  onClick={(e) => e.stopPropagation()}
                  target="mabadive_mail_client"
                >
                  {/* {emailAddress} */}
                  <span className="">{emailAddress}</span>
                </AppButton>
              )}
            </div>
          )}
        </div>
      </div>
      <DiveCenterBookingMembersListDiffItemCardDetails
        className="mt-2"
        aggregatedBookingResume={aggregatedBookingResume}
        bookingMemberFull={bookingMemberFull}
      />
    </div>
  );
}
