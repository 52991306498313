import React, { useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF,
  AppInputBooleanSwitch,
  AppInputBooleanSwitchRHF,
} from 'src/lib/form';
import { AppMessageLight } from '../../../../../../business/_core/modules/layout';
import { CustomerSpaceConfigFormModel } from '../model';
import { CustomerSpaceConfigFormCustomerUpdate_DiversContactInfo } from './CustomerSpaceConfigFormCustomerUpdate_DiversContactInfo';
import { CustomerSpaceConfigFormCustomerUpdate_DiversDivingInfo } from './CustomerSpaceConfigFormCustomerUpdate_DiversDivingInfo';
import { CustomerSpaceConfigFormCustomerUpdate_ExpectedDive } from './CustomerSpaceConfigFormCustomerUpdate_ExpectedDive';
import { CustomerSpaceConfigFormCustomerUpdate_HomeAddress } from './CustomerSpaceConfigFormCustomerUpdate_HomeAddress';

// note, copié/collé de BookingCustomerConfigEditFormCustomerUpdate
export const CustomerSpaceConfigFormCustomerUpdate = ({
  form,
}: {
  form: UseFormReturn<CustomerSpaceConfigFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isDiversPostalAddressInfoEnabledByDefault =
    clubReference === 'la-bulle-d-her-noirmoutier-27455';

  const formPrefix =
    'clubSettings.publicSettings.customerSpace.defaultBookingConfig' as const;

  const [
    customerUiEnableCustomerUpdate,
    customerUiPendingListDiversContactInfo,
    customerUiPendingListDiversDivingInfo,
    customerUiPendingListDiversDiversPostalAddressInfo,
    customerUiPendingListDiversExpectedDiveInfo,
  ] = useWatch({
    control,
    name: [
      `${formPrefix}.customerUi.enableCustomerUpdate`,
      `${formPrefix}.pendingList.diversContactInfo`,
      `${formPrefix}.pendingList.diversDivingInfo`,
      `${formPrefix}.pendingList.diversPostalAddressInfo`,
      `${formPrefix}.pendingList.diversExpectedDiveInfo`,
    ],
  });

  const customerUpdateRequested =
    customerUiPendingListDiversContactInfo ||
    customerUiPendingListDiversDivingInfo ||
    customerUiPendingListDiversDiversPostalAddressInfo ||
    customerUiPendingListDiversExpectedDiveInfo;
  useEffect(() => {
    if (!customerUpdateRequested && customerUiEnableCustomerUpdate) {
      setValue(`${formPrefix}.customerUi.enableCustomerUpdate`, null);
    }
  });

  const [displayExpertMode, setDisplayExpertMode] = useState(false);

  return (
    <>
      <AppFormControlRHF
        className={'w-full'}
        label="Informations clients"
        helpDescription="Demandez à vos clients de renseigner eux-même les informations dont vous avez besoin."
        control={control}
        name={`${formPrefix}.customerUi.enableCustomerUpdate`}
        renderComponent={(props) => (
          <AppInputBooleanSwitchRHF
            {...props}
            theme="danger"
            label="à renseigner depuis l'espace client"
            onChange={(value) => {
              if (value && !customerUpdateRequested) {
                setValue(`${formPrefix}.pendingList.diversContactInfo`, true);
                setValue(`${formPrefix}.pendingList.diversDivingInfo`, true);
                setValue(
                  `${formPrefix}.pendingList.diversPostalAddressInfo`,
                  isDiversPostalAddressInfoEnabledByDefault,
                );
                setValue(
                  `${formPrefix}.pendingList.diversExpectedDiveInfo`,
                  false,
                );
              }
            }}
          />
        )}
      />
      {customerUiEnableCustomerUpdate && (
        <div>
          <p className="mt-5 mb-2 text-gray-500 text-xs uppercase">
            Informations à renseigner par le client:
          </p>
          <div className="grid gap-4">
            <CustomerSpaceConfigFormCustomerUpdate_DiversContactInfo
              displayExpertMode={displayExpertMode}
              form={form}
            />
            <CustomerSpaceConfigFormCustomerUpdate_DiversDivingInfo
              displayExpertMode={displayExpertMode}
              form={form}
            />
            {customerSettings?.general?.homeAddress && (
              <CustomerSpaceConfigFormCustomerUpdate_HomeAddress
                displayExpertMode={displayExpertMode}
                form={form}
              />
            )}

            <CustomerSpaceConfigFormCustomerUpdate_ExpectedDive
              displayExpertMode={displayExpertMode}
              form={form}
            />
            <div className="my-2 flex justify-end">
              <AppInputBooleanSwitch
                label="Mode expert"
                value={displayExpertMode}
                onChange={() => setDisplayExpertMode(!displayExpertMode)}
              />
            </div>
            {displayExpertMode && (
              <AppMessageLight type="warn">
                Attention, les champs marqué comme obligatoire sont bloquants
                pour le client, qui ne pourra pas soumettre les autres
                informations. A utiliser avec parcimonie.
              </AppMessageLight>
            )}
          </div>
        </div>
      )}
    </>
  );
};
