/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_INQUIRY_TYPES, AppInquiryType } from '@mabadive/app-common-model';
import { inquiryFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputColorRHF,
  AppInputRHF,
  AppSingleSelect2HeadlessUIRHF,
} from 'src/lib/form';
import { AppIconsInquiry } from '../../../../../../business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from '../../../../../../business/auth/services';
import { ValueLabel } from '../../../../../../business/club/modules/_common/form';
import { AppMultiCheckboxesTailwindRHF } from '../../../../../../lib/form/components/AppMultiCheckboxes/AppMultiCheckboxesTailwindRHF';
import { useDiveModesOptions } from '../../../../../_components/options';
import { inqueryDefaultBuilder } from '../../_services';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../ClubSettingsInquiryEditPagePanel/useClubSettingsInquiryEditPagePanelLocalState.hook';
import { AppInquiryTypeLabel } from './AppInquiryTypeLabel';
import { AppInquiryFormModel } from './model';

export const ClubSettingsInquiryEditPageFormGeneral = ({
  localState,
  form,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  form: UseFormReturn<AppInquiryFormModel>;
  className?: string;
}) => {
  const { state, data } = localState;

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [color, inquiryType, enabled, autoActivationEnabled] = useWatch({
    control,
    name: [
      'settings.color',
      'type',
      'settings.enabled',
      'settings.autoActivation.enabled',
    ],
  });

  const diveModesOptions = useDiveModesOptions({
    type: 'all-included-disabled',
    format: 'short-ref',
  });

  const InquiryIcon = AppIconsInquiry[inquiryType];

  const inquiryTypesOptions: ValueLabel<AppInquiryType, React.ReactNode>[] =
    useMemo(
      () =>
        APP_INQUIRY_TYPES.map((type) => {
          const option: ValueLabel<AppInquiryType, React.ReactNode> = {
            value: type,
            label: (
              <AppInquiryTypeLabel
                className="px-1 py-px text-xs mr-2 sm:mr-4 md:mr-5"
                type={type}
              />
            ),
          };
          return option;
        }),
      [],
    );

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return (
    <div
      className={clsx('app-card p-4 border-t-8', className)}
      style={{
        borderColor: color,
      }}
    >
      <div
        className={clsx(
          'py-2 flex items-center gap-4 text-xl font-bold uppercase',
          'text-gray-600',
        )}
      >
        {InquiryIcon && <InquiryIcon className="h-6 w-6" />}
        {state.mode === 'create'
          ? 'Nouveau questionnaire'
          : data.initialValueIfExists?.name}
      </div>

      <div className={clsx('grid gap-x-4 gap-y-8')}>
        <div
          className={clsx('w-full grid sm:grid-cols-2 md:grid-cols-4 gap-4')}
        >
          <AppFormControlRHF
            className={'w-full'}
            label="Type de questionaire"
            control={control}
            name={'type'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                disableClearButton={true}
                theme={'tailwind'}
                className={'w-full'}
                options={inquiryTypesOptions}
                {...props}
                // IMPORTANT: si on utilise le champ "disabled", ça fait complètement bugger le formulaire (si on déplace une section, tout disparait!)
                // disabled={state.mode === 'edit'}
                onChange={(inquiryType: AppInquiryType) => {
                  if (inquiryType) {
                    setValue(
                      'name',
                      inquiryFormatter.formatInquiryType(inquiryType),
                    );
                    const { sections, settings } =
                      inqueryDefaultBuilder.buildFromType({
                        inquiryType,
                      });
                    setValue('sections', sections);
                    setValue('settings.shortName', settings.shortName);
                    setValue('settings.color', settings.color);
                    setValue(
                      'settings.autoActivation',
                      settings.autoActivation,
                    );
                  }
                }}
              />
            )}
          />
          {inquiryType && (
            <>
              <AppFormControlRHF
                className={'w-full'}
                label="Nom du questionnaire"
                control={control}
                name={'name'}
                required={true}
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
              <AppFormControlRHF
                className={'w-full'}
                label="Diminutif"
                control={control}
                name={'settings.shortName'}
                required={true}
                validation={{
                  rules: {
                    minLength: 1,
                    maxLength: 6,
                  },
                }}
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
              <AppFormControlRHF
                className={'w-full'}
                label={'Couleur'}
                required={true}
                control={control}
                name={'settings.color'}
                renderComponent={(props) => (
                  <AppInputColorRHF
                    {...props}
                    required={false}
                    className={'my-2 text-center text-xl font-bold'}
                  />
                )}
              />
            </>
          )}
        </div>
        {
          <div className="grid gap-4">
            {
              <div
                className={clsx(
                  'w-full grid sm:grid-cols-2 md:grid-cols-4 gap-4',
                )}
              >
                <div className="md:col-span-2 2xl:col-span-1 flex gap-4 justify-between">
                  {isSuperAdmin && (
                    <AppFormControlRHF
                      className={'w-full'}
                      label="Activé"
                      control={control}
                      name={'settings.enabled'}
                      visibility="super-admin"
                      renderComponent={(props) => (
                        <AppInputBooleanSwitchRHF
                          {...props}
                          className="mt-1"
                          label={'Activé'}
                          labelToggleFalse="Désactivé"
                        />
                      )}
                    />
                  )}
                  {enabled && (
                    <AppFormControlRHF
                      className={'w-full'}
                      label="Activation automatique"
                      control={control}
                      name={'settings.autoActivation.enabled'}
                      renderComponent={(props) => (
                        <AppInputBooleanSwitchRHF
                          {...props}
                          className="mt-1"
                          label={'Activé'}
                          labelToggleFalse="Désactivé"
                        />
                      )}
                    />
                  )}
                </div>

                {enabled && autoActivationEnabled && (
                  <>
                    <div className="md:col-span-2 2xl:col-span-1 grid grid-cols-2 gap-4">
                      <AppFormControlRHF
                        className={'w-full'}
                        label="Âge mini"
                        control={control}
                        validation={{
                          rules: {
                            min: 0,
                          },
                        }}
                        name={'settings.autoActivation.age.min'}
                        required={false}
                        renderComponent={(props) => (
                          <AppInputRHF {...props} fullWidth type="number" />
                        )}
                      />
                      <AppFormControlRHF
                        className={'w-full'}
                        label="Âge maxi"
                        control={control}
                        validation={{
                          rules: {
                            min: 0,
                          },
                        }}
                        name={'settings.autoActivation.age.max'}
                        required={false}
                        renderComponent={(props) => (
                          <AppInputRHF {...props} fullWidth type="number" />
                        )}
                      />
                    </div>
                    <AppFormControlRHF
                      className={'md:col-span-3 2xl:col-span-2 w-full'}
                      label={'Prestations concernées'}
                      control={control}
                      name={'settings.autoActivation.diveModes'}
                      renderComponent={(props) => (
                        <AppMultiCheckboxesTailwindRHF
                          {...props}
                          options={diveModesOptions}
                          direction="horizontal"
                          labelPosition="right"
                        />
                      )}
                    />
                  </>
                )}
              </div>
            }
            {enabled && (
              <div
                className={clsx(
                  'w-full grid sm:grid-cols-2 md:grid-cols-4 gap-4',
                )}
              >
                <AppFormControlRHF
                  className={'w-full'}
                  label="Durée de validité (en année)"
                  control={control}
                  validation={{
                    rules: {
                      min: 0,
                    },
                  }}
                  name={'settings.docValidityInYears'}
                  required={false}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth type="number" />
                  )}
                />
                <AppFormControlRHF
                  label={'Signature manuscrite'}
                  helpDescription={
                    'Le client devra reproduire sa signature manuscrite avec le doigt ou la souris'
                  }
                  control={control}
                  name={'settings.consents.padSignature.enabled'}
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                  )}
                />
                <AppFormControlRHF
                  label={'Exiger l\'acceptation du RGPD'}
                  control={control}
                  name={'settings.consents.gdpr.enabled'}
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
                  )}
                />
              </div>
            )}
            <div className="grid gap-4 lg:grid-cols-2">
              <AppFormControlRHF
                className="w-full"
                label="Commentaire privé 🚫"
                control={control}
                name="settings.privateComment"
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    fullWidth
                    multiline
                    rowsMax={15}
                    rows={2}
                  />
                )}
              />
              {/* <AppFormControlRHF
                className="w-full"
                label="Commentaire client 👨‍🦰👩‍🦰"
                control={control}
                name="settings.details.customerComment"
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    fullWidth
                    multiline
                    rowsMax={15}
                    rows={2}
                  />
                )}
              /> */}
            </div>
          </div>
        }
      </div>
    </div>
  );
};
