import React from 'react';
import { PRO_BookingResume } from '../../DiverBookingPage/models';
import { ClubDiverProfilePicture } from './ClubDiverProfilePicture';
import { DiveCenterBookingMembersListDiffItemCard } from './DiveCenterBookingMembersListDiffItemCard';
import { DiveCenterBookingMemberDiff } from './model';
import { BookingResumeOnClickMemberAction } from './model/BookingResumeOnClickMemberAction.type';

export function DiveCenterBookingMembersListDiffItem({
  aggregatedBookingResume,
  memberDiff,
  showProfilePhoto,
  onClick,
}: {
  aggregatedBookingResume: PRO_BookingResume;
  memberDiff: DiveCenterBookingMemberDiff;
  showProfilePhoto: boolean;
  onClick?: (
    memberDiff: DiveCenterBookingMemberDiff,
    action: BookingResumeOnClickMemberAction,
  ) => void;
}) {
  const { status, original, final, changes } = memberDiff;

  const unchangedClass = 'grayscale opacity-80';
  return (
    <li className="text-xs">
      <button className={'app-card w-full block cursor-default select-text'}>
        <div className={'w-full px-2 py-2 flex gap-2'}>
          {showProfilePhoto && (
            <ClubDiverProfilePicture
              className="mt-1.5 size-[6.5rem] cursor-pointer"
              shape="square"
              diver={memberDiff.final.diver}
              // onClick={(e) => {
              //   e.stopPropagation();
              //   onClick(bookingMemberFull, 'edit');
              // }}
            />
          )}

          <DiveCenterBookingMembersListDiffItemCard
            className="flex-grow"
            aggregatedBookingResume={aggregatedBookingResume}
            memberDiff={memberDiff}
          />
        </div>
      </button>
    </li>
  );
}
