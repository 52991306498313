/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlV2,
  AppInputBoolean,
  AppInputBooleanRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppExpansionPanel } from '../../../../../business/_core/modules/layout';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm_CustomerContact = ({
  localState,
  className,
}: {
  localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
  className?: string;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const clubResume = useClubResume();
  const generalSettings = clubResume.clubSettings?.general;

  const [
    generalExternalRef,
    generalExternalRefLabel,
    generalCustomField1,
    generalCustomField2,
    generalCustomField3,
  ] = useWatch({
    control,
    name: [
      'clubSettings.customer.general.externalRef',
      'clubSettings.customer.general.externalRefLabel',
      'clubSettings.customer.general.customField1',
      'clubSettings.customer.general.customField2',
      'clubSettings.customer.general.customField3',
    ],
  });

  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <AppExpansionPanel
      className={className}
      isExpandable={true}
      isExpanded={isExpanded}
      expandPanel={() => {
        return () => setIsExpanded(!isExpanded);
      }}
      panelId="archived-purchases"
      renderSummaryTitle={() => (
        <div className="flex justify-between items-center">
          <h2 className="text-left uppercase text-lg leading-6 font-medium text-gray-800">
            Client - coordonnées
          </h2>
          {!isExpanded && (
            <span className="hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR CONFIGURER
            </span>
          )}
        </div>
      )}
      renderSummaryExtra={() => null}
      renderDetails={() => (
        <div className="flex flex-col gap-y-2">
          <AppFormControlV2
            disabled={true}
            renderComponent={() => (
              <AppInputBoolean
                value={true}
                disabled={true}
                type="checkbox"
                label={'Champs toujours affichés (nom, prénom, email...)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.profilePhoto"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Photo de profil'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.homeAddress"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Adresse postale'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.languageCode"
            renderComponent={(props) => (
              <AppInputBooleanRHF {...props} type="checkbox" label={'Langue'} />
            )}
          />
          <div className="flex gap-4 flex-wrap">
            <AppFormControlRHF
              control={control}
              name="clubSettings.customer.general.externalRef"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  label={'ID perso'}
                  onChange={(value) => {
                    if (value && !generalExternalRefLabel?.trim()?.length) {
                      setValue(
                        'clubSettings.customer.general.externalRefLabel',
                        'N° client',
                      );
                    }
                  }}
                />
              )}
            />
            {generalExternalRef && (
              <AppFormControlRHF
                className="max-x-40"
                control={control}
                name="clubSettings.customer.general.externalRefLabel"
                label="Nom du champ"
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
            )}
          </div>
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.identificationNumber"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'N° CNI ou Passeport'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.residentType"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Résidence (résident ou vacancier)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.customerOrigin"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Origine client (Internet, bouche à oreille...)'}
              />
            )}
          />
          {generalSettings?.accommodations?.enabled && (
            <AppFormControlRHF
              control={control}
              name="clubSettings.customer.general.accomodation"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  label={'Hébergement (dans quel hôtel)'}
                />
              )}
            />
          )}
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.pickup"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={
                  'Pickup (liste des personnes à aller chercher à leur hôtel chaque jour)'
                }
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.sanitaryPass"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Pass sanitaire (oui/non)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.newsletter"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Inscription à la newsletter (oui/non)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.phoneNumber2"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Second numéro de téléphone'}
              />
            )}
          />
          <AppFormControlV2
            disabled={true}
            renderComponent={() => (
              <AppInputBoolean
                value={true}
                disabled={true}
                type="checkbox"
                label={'Date de naissance (toujours affiché)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.age"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={
                  'Âge (conseil: préférer renseigner plutôt la date de naissance)'
                }
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.size"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Taille (cm)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.weight"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Poids (Kg)'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.shoeSize"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Pointure'}
              />
            )}
          />
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.general.isCustomer"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                label={'Client non-plongeur'}
              />
            )}
          />
          <div className="flex gap-4 flex-wrap">
            <AppFormControlRHF
              control={control}
              name="clubSettings.customer.general.customField1"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  label={'Champ personnalisé N°1'}
                />
              )}
            />
            {generalCustomField1 && (
              <AppFormControlRHF
                className="max-x-40"
                control={control}
                name="clubSettings.customer.general.customField1Label"
                label="Nom du champ"
                required={true}
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
            )}
          </div>
          {(generalCustomField1 || generalCustomField2) && (
            <div className="flex gap-4 flex-wrap">
              <AppFormControlRHF
                control={control}
                name="clubSettings.customer.general.customField2"
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    label={'Champ personnalisé N°2'}
                  />
                )}
              />
              {generalCustomField2 && (
                <AppFormControlRHF
                  className="max-x-40"
                  control={control}
                  name="clubSettings.customer.general.customField2Label"
                  label="Nom du champ"
                  required={true}
                  renderComponent={(props) => <AppInputRHF {...props} />}
                />
              )}
            </div>
          )}
          {((generalCustomField1 && generalCustomField2) ||
            generalCustomField3) && (
            <div className="flex gap-4 flex-wrap">
              <AppFormControlRHF
                control={control}
                name="clubSettings.customer.general.customField3"
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    label={'Champ personnalisé N°3'}
                  />
                )}
              />
              {generalCustomField3 && (
                <AppFormControlRHF
                  className="max-x-40"
                  control={control}
                  name="clubSettings.customer.general.customField3Label"
                  label="Nom du champ"
                  required={true}
                  renderComponent={(props) => <AppInputRHF {...props} />}
                />
              )}
            </div>
          )}
        </div>
      )}
    />
  );
};
