"use strict";
exports.__esModule = true;
exports.APP_PAYMENT_METHODS_ALL = exports.APP_PAYMENT_METHODS_CREDIT_NOTE = exports.APP_PAYMENT_METHODS_DEFAULT = void 0;
// NOTE, en cas d'ajout de AppPaymentMethod, modifier DASHBOARD_PAYMENTS_BY_PERIOD_VIEW_METHODS
// et re-créer la vue via une migration en utilisant dashboardPaymentsDBMigrator
exports.APP_PAYMENT_METHODS_DEFAULT = [
    'cash',
    'credit-card',
    'check',
    'bank-transfer',
    'holidays-voucher',
];
exports.APP_PAYMENT_METHODS_CREDIT_NOTE = [
    'cash',
    'credit-card',
    'bank-transfer',
];
exports.APP_PAYMENT_METHODS_ALL = [
    'cash',
    'credit-card',
    'credit-card-local',
    'credit-card-visa',
    'amex',
    'check',
    'bank-transfer',
    'holidays-voucher',
    'free',
    'booking-deposit',
    'gift-voucher',
    'gift-card',
    'paybox',
    'miles',
    'atn',
    'titeti-ai-a',
    'voucher-trc',
    'voucher',
    'agency',
    'axonaut',
    'lydia',
    'stripe',
    'sumup',
    'twint',
    'paylib',
    'systempay',
    'paypal',
    'yavin',
];
