/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  APP_INQUIRY_QUESTION_ANSWER_FORMATS,
  APP_INQUIRY_QUESTION_ANSWER_TEXT_SHORT_FORMATS,
  AppInquiryQuestionAnswerDetailsDateType,
  AppInquiryQuestionAnswerFormat,
  AppInquiryQuestionAnswerTextShortFormat,
} from '@mabadive/app-common-model';
import { inquiryFormatter } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleSelect2HeadlessUIRHF,
  YES_NO_OPTIONS,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { ValueLabel } from '../../../../../../business/club/modules/_common/form';
import { AppInputDatePickerMaterialRHF } from '../../../../../../stories/components/04-form';
import { AppInquiryFormModel } from './model';

export type ClubSettingsInquiryEditPageFormQuestionOnClickAction =
  | 'move-down'
  | 'move-up'
  | 'delete'
  | 'clone';
export const ClubSettingsInquiryEditPageFormQuestion = ({
  className,
  form,
  fieldArray,
  field,
  sectionIndex,
  questionIndex,
  onClick,
}: {
  className?: string;
  form: UseFormReturn<AppInquiryFormModel>;
  fieldArray: UseFieldArrayReturn<
    AppInquiryFormModel,
    `sections.${number}.questions`,
    'questionId'
  >;
  field: FieldArrayWithId<
    AppInquiryFormModel,
    `sections.${number}.questions`,
    'questionId'
  >;
  sectionIndex: number;
  questionIndex: number;
  onClick: (
    action: ClubSettingsInquiryEditPageFormQuestionOnClickAction,
  ) => void;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    getValues,
  } = form;

  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const { fields, append, prepend, remove, swap, move, insert } = fieldArray;

  const formAttributeBaseName =
    `sections.${sectionIndex}.questions.${questionIndex}` as const;

  const isMoveDownAllowed =
    fieldArray.fields.length > 1 &&
    questionIndex + 1 < fieldArray.fields.length;
  const isMoveUpAllowed = fieldArray.fields.length > 1 && questionIndex !== 0;

  const [answerDetailsFormat] = useWatch({
    control,
    name: [`${formAttributeBaseName}.answerDetails.format`],
  });

  const answerFormatOptions: ValueLabel<
    AppInquiryQuestionAnswerFormat,
    string
  >[] = useMemo(
    () =>
      APP_INQUIRY_QUESTION_ANSWER_FORMATS.map((format) => {
        const option: ValueLabel<AppInquiryQuestionAnswerFormat, string> = {
          value: format,
          label: inquiryFormatter.formatQuestionAnswerFormat(format),
        };
        return option;
      }),
    [],
  );
  const answerTextShortFormatOptions: ValueLabel<
    AppInquiryQuestionAnswerTextShortFormat,
    string
  >[] = useMemo(
    () =>
      APP_INQUIRY_QUESTION_ANSWER_TEXT_SHORT_FORMATS.map((format) => {
        const option: ValueLabel<
          AppInquiryQuestionAnswerTextShortFormat,
          string
        > = {
          value: format,
          label: inquiryFormatter.formatTextShortFormat(format),
        };
        return option;
      }),
    [],
  );
  const answerDateInitialViewOptions: ValueLabel<
    AppInquiryQuestionAnswerDetailsDateType,
    string
  >[] = useMemo(
    () => [
      {
        value: 'any',
        label: 'Passée ou future',
      },
      {
        value: 'past',
        label: 'Passée',
      },
      {
        value: 'future',
        label: 'Future',
      },
      {
        value: 'birthdate',
        label: 'Date de naissance',
      },
    ],
    [],
  );

  return (
    <div
      className={clsx(
        'border-l-4 border-gray-600 pl-2 grid md:flex gap-2 lg:gap-4 items-start',
        className,
      )}
    >
      <div
        className={clsx(
          'h-full flex md:flex-col md:justify-start gap-2',
          fieldArray.fields.length === 1 && 'invisible',
        )}
      >
        <Tippy
          delay={[1000, 100]}
          placement="top"
          content={'Déplacer au dessus'}
        >
          <AppIconsAction.up
            className={clsx(
              'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
              isMoveUpAllowed
                ? 'hover:bg-gray-600 cursor-pointer'
                : 'opacity-50 cursor-not-allowed',
            )}
            onClick={() => isMoveUpAllowed && onClick('move-up')}
          />
        </Tippy>
        <Tippy
          delay={[1000, 100]}
          placement="top"
          content={'Déplacer en dessous'}
        >
          <AppIconsAction.down
            className={clsx(
              'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
              isMoveDownAllowed
                ? 'hover:bg-gray-600 cursor-pointer'
                : 'opacity-50 cursor-not-allowed',
            )}
            onClick={() => isMoveDownAllowed && onClick('move-down')}
          />
        </Tippy>
      </div>
      <div className="flex-grow">
        <div className="w-full grid gap-2">
          <AppFormControlRHF
            label="titre"
            control={control}
            name={`${formAttributeBaseName}.description.main.title`}
            validation={{
              rules: {
                maxLength: 200,
              },
            }}
            required={true}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth={true} type="text" />
            )}
          />
          <AppFormControlRHF
            label="Sous-titre"
            control={control}
            name={`${formAttributeBaseName}.description.main.subTitle`}
            validation={{
              rules: {
                maxLength: 200,
              },
            }}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth={true} type="text" />
            )}
          />
          <div className="flex gap-2 md:grid md:grid-cols-3 xl:grid-cols-5">
            <AppFormControlRHF
              className={'w-60'}
              label="Type de réponse"
              control={control}
              name={`${formAttributeBaseName}.answerDetails.format`}
              required={true}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  disableClearButton={true}
                  theme={'tailwind'}
                  className={'w-full'}
                  options={answerFormatOptions}
                  {...props}
                  onChange={(answerDetailsFormat) => {
                    if (answerDetailsFormat !== 'yes-no') {
                      setValue(
                        `${formAttributeBaseName}.answerDetails.required`,
                        undefined,
                      );
                    }
                  }}
                />
              )}
            />
            <AppFormControlRHF
              label="Réponse obligatoire?"
              control={control}
              name={`${formAttributeBaseName}.answerDetails.required`}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
            {answerDetailsFormat === 'yes-no' && (
              <AppFormControlRHF
                label="Bonne réponse attendue"
                helpDescription={
                  'Si la réponse est différente de cette attendue, un warning sera affiché sur l\'espace PRO'
                }
                control={control}
                name={`${formAttributeBaseName}.answerDetails.formatOptions.yesNo.expectedValue`}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    disableClearButton={false}
                    theme={'tailwind'}
                    className={'w-full'}
                    options={YES_NO_OPTIONS}
                    {...props}
                  />
                )}
              />
            )}
            {answerDetailsFormat === 'text-short' && (
              <AppFormControlRHF
                label="Format spécial"
                helpDescription={'Email ou téléphone'}
                control={control}
                name={`${formAttributeBaseName}.answerDetails.formatOptions.textShort.format`}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    disableClearButton={false}
                    theme={'tailwind'}
                    className={'w-full'}
                    options={answerTextShortFormatOptions}
                    {...props}
                  />
                )}
              />
            )}
            {answerDetailsFormat === 'date' && (
              <>
                <AppFormControlRHF
                  label="Type de date"
                  control={control}
                  name={`${formAttributeBaseName}.answerDetails.formatOptions.date.type`}
                  renderComponent={(props) => (
                    <AppSingleSelect2HeadlessUIRHF
                      disableClearButton={false}
                      theme={'tailwind'}
                      className={'w-full'}
                      options={answerDateInitialViewOptions}
                      {...props}
                    />
                  )}
                />
                <AppFormControlRHF
                  label="Date mini"
                  control={control}
                  name={`${formAttributeBaseName}.answerDetails.formatOptions.date.minDate`}
                  renderComponent={(props) => (
                    <AppInputDatePickerMaterialRHF
                      className="w-full"
                      initialView={'years'}
                      {...props}
                    />
                  )}
                />
                <AppFormControlRHF
                  label="Date maxi"
                  control={control}
                  name={`${formAttributeBaseName}.answerDetails.formatOptions.date.maxDate`}
                  renderComponent={(props) => (
                    <AppInputDatePickerMaterialRHF
                      className="w-full"
                      initialView={'years'}
                      {...props}
                    />
                  )}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-2 md:gap-6">
        <Tippy delay={[1000, 100]} placement="top" content={'Supliquer'}>
          <AppIconsAction.clone
            className="p-1 w-8 h-8 bg-green-200 hover:bg-green-600 rounded-full text-white cursor-pointer"
            onClick={() => {
              onClick('clone');
            }}
          />
        </Tippy>
        <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
          <AppIconsAction.delete
            className="p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer"
            onClick={() => {
              onClick('delete');
            }}
          />
        </Tippy>
      </div>
    </div>
  );
};
