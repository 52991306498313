import {
  AppPaymentMethod,
  AppPaymentMethodUnknown,
} from '@mabadive/app-common-model';

export const paymentMethodFormatter = {
  formatPaymentMethod,
};

function formatPaymentMethod(
  type: AppPaymentMethod | AppPaymentMethodUnknown,
  {
    format,
  }: {
    format: 'long';
  } = {
    format: 'long',
  },
): string {
  // if (format === 'long') {
  switch (type) {
    case 'gift-voucher':
      return 'Bon cadeau';
    case 'gift-card':
      return 'Carte cadeau';
    case 'cash':
      return 'Espèces';
    case 'credit-card':
      return 'CB';
    case 'credit-card-local':
      return 'CB locale';
    case 'credit-card-visa':
      return 'CB visa';
    case 'amex':
      return 'American Express';
    case 'check':
      return 'Chèque';
    case 'bank-transfer':
      return 'Virement';
    case 'booking-deposit':
      return 'Acompte';
    case 'holidays-voucher':
      return 'Chèque vacances';
    case 'voucher-trc':
      return 'Voucher TRC';
    case 'voucher':
      return 'Voucher';
    case 'agency':
      return 'Agence';
    case 'paybox': {
      // top-dive
      return 'Paybox';
    }
    case 'miles': {
      // top-dive
      return 'Miles';
    }
    case 'atn': {
      // top-dive
      return 'ATN';
    }
    case 'titeti-ai-a': {
      // top-dive
      return `Titeti'ai'a`;
    }
    case 'twint': {
      // scuba-libre
      return 'Twint';
    }
    case 'sumup': {
      // scuba-libre
      return 'SumUp';
    }
    case 'paypal': {
      return 'Paypal';
    }
    case 'lydia': {
      return 'Lydia';
    }
    case 'paylib': {
      return 'Paylib';
    }
    case 'systempay': {
      return 'Systempay';
    }
    case 'axonaut': {
      return 'Axonaut';
    }
    case 'yavin': {
      return 'Yavin';
    }
    case 'free': {
      // top-dive
      return 'Gratuité';
    }

    case 'unknown':
      return 'Inconnu';
  }
  // } else {
  //   switch (type) {
  //     case 'cash':
  //       return 'ESP';
  //     case 'credit-card':
  //       return 'CB';
  //     case 'amex':
  //       return 'AMEX';
  //     case 'check':
  //       return 'CHQ';
  //     case 'bank-transfer':
  //       return 'VIR';
  //     case 'holidays-voucher':
  //       return 'VAC';

  //       case 'paybox' : { // top-dive
  //         return 'PBOX'
  //       }
  //       case 'miles' : { // top-dive
  //         return 'Miles'
  //       }
  //       case 'atn' : { // top-dive
  //         return 'ATN'
  //       }
  //       case 'titeti-ai-a': { // top-dive
  //         return 'Titeti'ai'a'
  //       }
  //       case 'twint' : { // scuba-libre
  //         return 'Twint'
  //       }
  //       case 'sumup': { // scuba-libre
  //         return 'SumUp'
  //       }
  //     case 'unknown':
  //       return '?';
  //   }
  // }

  // default
  return type;
}
