import { ClubAuthUserProfile } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanSwitchRHF } from 'src/lib/form';

export function ClubSettingsUserProfileEditFormAuthParticipantCommunication({
  form,
  className,
}: {
  form: UseFormReturn<ClubAuthUserProfile, object>;
  className?: string;
}) {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  return (
    <div className="mt-1 grid gap-4 md:grid-cols-2">
      <div className="border-2 border-status-success">
        <div className="font-bold bg-status-success/10 text-status-success border-b border-status-success uppercase text-base flex items-center gap-2 px-2">
          <AppHeroIcons.eye className={'inline w-4 h-4 font-bold'} />
          Accès
        </div>
        <div className="m-2 grid gap-4 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2"></div>
      </div>
      <div className="border-2 border-status-warn">
        <div className="font-bold bg-status-warn/10 text-status-warn border-b border-status-warn uppercase text-base flex items-center gap-2 px-2">
          <AppIconsAction.edit className={'inline w-4 h-4 font-bold'} />
          Actions
        </div>

        <div className="m-2 grid gap-4 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2">
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.communication.action.sendSessionMessage"
            label={'Envoi d\'un message aux participants d\'une session'}
            theme={'warn'}
          />
          <div className="flex gap-4 flex-wrap">
            {' '}
            <AppInputBooleanSwitchRHF
              control={control}
              name="authorizations.communication.action.sendSessionMessageEmail"
              label={'email'}
              theme={'success'}
            />
            <AppInputBooleanSwitchRHF
              control={control}
              name="authorizations.communication.action.sendSessionMessageSms"
              label={'SMS'}
              theme={'success'}
            />
            <AppInputBooleanSwitchRHF
              control={control}
              name="authorizations.communication.action.sendSessionMessageWhatsApp"
              label={'Whatsapp'}
              theme={'success'}
            />
          </div>
        </div>
        <div className="m-2 grid gap-4 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2">
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.communication.action.sendBookingMessage"
            label={'Envoi d\'un message aux clients d\'une réservation'}
            theme={'warn'}
          />
          <div className="flex gap-4 flex-wrap">
            {' '}
            <AppInputBooleanSwitchRHF
              control={control}
              name="authorizations.communication.action.sendBookingMessageEmail"
              label={'email'}
              theme={'success'}
            />
            <AppInputBooleanSwitchRHF
              control={control}
              name="authorizations.communication.action.sendBookingMessageSms"
              label={'SMS'}
              theme={'success'}
            />
            <AppInputBooleanSwitchRHF
              control={control}
              name="authorizations.communication.action.sendBookingMessageWhatsApp"
              label={'Whatsapp'}
              theme={'success'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
