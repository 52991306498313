import {
  dateService,
  diveModeFormatter,
  postalAddressFormatter,
  smsLinkBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  AppGenericStatusIcon,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';
import {
  PRO_BookingMemberFull,
  PRO_BookingResume,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { ClubDiverCardAttributeMedicalCertificateStatus } from 'src/business/club/modules/club-divers/components/ClubDiverCard/attributes/ClubDiverCardAttributeMedicalCertificateStatus';
import { ClubDiverCardAttributeMedicalFormStatus } from 'src/business/club/modules/club-divers/components/ClubDiverCard/attributes/ClubDiverCardAttributeMedicalFormStatus';
import { useClubSettings } from '../../../../../data/hooks';

export function DiveCenterBookingMembersListDiffItemCardDetails({
  aggregatedBookingResume,
  bookingMemberFull,
  className,
}: {
  aggregatedBookingResume: PRO_BookingResume;
  bookingMemberFull: PRO_BookingMemberFull;
  className?: string;
}) {
  const diver = bookingMemberFull?.diver;
  const memberDetails = bookingMemberFull?.bookingMember?.details;

  const maxDateOrToday = useMemo(() => {
    return aggregatedBookingResume?.datesRange?.maxDate ?? new Date();
  }, [aggregatedBookingResume?.datesRange?.maxDate]);

  const insuranceDateBadgeStatus: AppGenericStatus = useMemo(() => {
    if (!bookingMemberFull.bookingMember?.details?.insurance?.expirationDate) {
      return 'warn';
    }
    if (
      !dateService.isBefore(
        bookingMemberFull.bookingMember?.details?.insurance?.expirationDate,
        maxDateOrToday,
      )
    ) {
      return 'success';
    }
    return 'error';
  }, [
    bookingMemberFull.bookingMember?.details?.insurance?.expirationDate,
    maxDateOrToday,
  ]);

  const insuranceDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(insuranceDateBadgeStatus),
    [insuranceDateBadgeStatus],
  );
  const lastDiveDateBadgeStatus: AppGenericStatus = useMemo(() => {
    if (!memberDetails?.lastDiveDate) {
      return 'error';
    }
    if (dateService.getAgeInMonths(memberDetails?.lastDiveDate) < 7) {
      return 'success';
    }
    if (dateService.getAgeInMonths(memberDetails?.lastDiveDate) < 12) {
      return 'warn';
    }
    return 'error';
  }, [memberDetails?.lastDiveDate]);

  const lastDiveDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(lastDiveDateBadgeStatus),
    [lastDiveDateBadgeStatus],
  );

  const clubSettings = useClubSettings();

  const phoneNumberClean = useMemo(
    () => smsLinkBuilder.cleanPhoneNumber(diver?.phoneNumber),
    [diver?.phoneNumber],
  );
  const emailAddress = useMemo(
    () => diver?.emailAddress?.trim(),
    [diver?.emailAddress],
  );

  return (
    <div className={clsx('', className)}>
      <div
        className={`flex justify-between sm:justify-start flex-wrap gap-x-4 gap-y-2 ${className}`}
      >
        {memberDetails?.expectedDive?.diveMode && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Prestation souhaitée
            </h3>
            <p className="text-gray-600 font-bold uppercase">
              {diveModeFormatter.formatDiveMode(
                memberDetails?.expectedDive?.diveMode,
                {
                  format: 'long-label',
                  diveModesConf: clubSettings?.ui?.diveMode,
                },
              )}
            </p>
          </div>
        )}
        {(diver?.medicalCertificateDate ||
          diver?.medicalCertificateChecked) && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Certificat médical
            </h3>
            <ClubDiverCardAttributeMedicalCertificateStatus diver={diver} />
          </div>
        )}
        {(bookingMemberFull?.bookingMember?.details?.medicalForm?.date ||
          bookingMemberFull?.bookingMember?.details?.medicalForm?.filled) && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Formulaire médical
            </h3>
            <ClubDiverCardAttributeMedicalFormStatus
              bookingMember={bookingMemberFull?.bookingMember}
            />
          </div>
        )}
        {memberDetails?.lastDiveDate && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Dernière plongée
            </h3>
            <div className={`${lastDiveDateBadgeStatusClass}`}>
              <div className="flex items-center">
                <AppGenericStatusIcon
                  status={lastDiveDateBadgeStatus}
                  className={'w-4 h-4 '}
                />
                <div className="grow">
                  <span className="ml-1">
                    {dateService.formatUTC(
                      memberDetails?.lastDiveDate,
                      'DD/MM/YYYY',
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {memberDetails?.totalDivesCount > 0 && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              NB plongées
            </h3>
            <p className="text-gray-600 font-bold">
              {memberDetails?.totalDivesCount}
            </p>
          </div>
        )}

        {diver?.emergencyContact?.trim() && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Contact d'urgence
            </h3>
            <p className={'text-gray-500'}>{diver?.emergencyContact}</p>
          </div>
        )}
        {diver?.homeAddress && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Adresse postale
            </h3>
            <p className={'text-gray-500'}>
              {postalAddressFormatter.format(diver.homeAddress)}
            </p>
          </div>
        )}
        {(memberDetails?.insurance?.hasInsurance === true ||
          memberDetails?.insurance?.hasInsurance === false) && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Assurance
            </h3>
            <div className={`${insuranceDateBadgeStatusClass}`}>
              {memberDetails?.insurance?.hasInsurance === true ? (
                <div className="flex flex-col gap-0.5 items-start">
                  {memberDetails?.insurance?.number && (
                    <div>{memberDetails?.insurance?.number}</div>
                  )}
                  <div className="flex items-center">
                    {memberDetails?.insurance?.expirationDate && (
                      <>
                        <AppGenericStatusIcon
                          status={insuranceDateBadgeStatus}
                          className={'w-4 h-4 '}
                        />
                        <div className="grow">
                          <span className="ml-1">
                            {dateService.formatUTC(
                              memberDetails?.insurance?.expirationDate,
                              'DD/MM/YYYY',
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="text-xs text-status-error">NON</div>
              )}
            </div>
          </div>
        )}
        {diver?.details?.identificationNumber && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              N° CNI ou Passeport
            </h3>
            <p className={'text-gray-500'}>
              {diver.details?.identificationNumber}
            </p>
          </div>
        )}
        {diver?.details?.newsletter?.registrationActive && (
          <div className="block text-left">
            <h3 className="text-gray-500 uppercase font-normal max-w-full">
              Newsletter
            </h3>
            <div className="text-xs text-status-success">OUI</div>
          </div>
        )}
        {bookingMemberFull?.bookingMember?.details?.expectedDive
          ?.customerComment && (
          <div className="mt-1 block text-left">
            {/* <h3 className="text-gray-500 uppercase font-normal max-w-full">
                  Commentaire
                </h3> */}
            <p className="text-red-400 font-bold italic">
              "
              {
                bookingMemberFull?.bookingMember?.details?.expectedDive
                  ?.customerComment
              }
              "
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
