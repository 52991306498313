/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';

import { AppInputBooleanSwitch } from '../../../../../../../lib/form';
import {
  ClubSettingsServicesDialogInitialState,
  ClubSettingsServicesFormModel,
} from '../../../_model';
import { ClubSettingsServicesFirstDiveFormNames } from '../../02.first-dive/form/ClubSettingsServicesFirstDiveFormNames';
import { ClubSettingsServicesActivitiesFormColors } from './ClubSettingsServicesActivitiesFormColors';
import { ClubSettingsServicesActivitiesFormOther } from './ClubSettingsServicesActivitiesFormOther';
import { ClubSettingsServicesActivitiesFormScuba } from './ClubSettingsServicesActivitiesFormScuba';
import { ClubSettingsServicesActivitiesFormTraining } from './ClubSettingsServicesActivitiesFormTraining';

export const ClubSettingsServicesActivitiesForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
  initialState: ClubSettingsServicesDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const [firstDiveEnabled] = useWatch({
    control,
    name: ['clubSettings.firstDive.enabled'],
  });
  const [displayExpertMode, setDisplayExpertMode] = useState(false);
  return (
    <div className="grid gap-4 md:gap-6">
      <div className="grid gap-4 md:grid-cols-3">
        <ClubSettingsServicesActivitiesFormScuba form={form} />{' '}
        <ClubSettingsServicesActivitiesFormTraining form={form} />
        <ClubSettingsServicesActivitiesFormOther form={form} />
      </div>
      {firstDiveEnabled && (
        <ClubSettingsServicesFirstDiveFormNames form={form} />
      )}
      <ClubSettingsServicesActivitiesFormColors
        form={form}
        displayExpertMode={displayExpertMode}
      />
      {isSuperAdmin && (
        <div className="my-2 flex justify-end">
          <AppInputBooleanSwitch
            label="Mode expert"
            value={displayExpertMode}
            onChange={() => setDisplayExpertMode(!displayExpertMode)}
          />
        </div>
      )}
    </div>
  );
};
