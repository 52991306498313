/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  APP_CURRENCIES,
  AppCurrency,
  AppCurrencyIsoCode,
  ClubPaymentCurrencySettings,
  PURCHASE_PAYMENT_STATES,
  PurchasePaymentState,
} from '@mabadive/app-common-model';
import {
  paymentMethodFormatter,
  purchasePaymentStateFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppCurrencyChangeLabel } from 'src/business/club/modules/_common/ui';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormLabel } from 'src/lib/form/components/AppFormLabel';
import {
  useAppCurrencies,
  useAppCurrencyMain,
  useAppCurrencyOptions,
  useAppPaymentMethodsOptions,
} from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { DiverPurchasePaymentEditorDialogLocalState } from '../../useDiverPurchasePaymentEditorDialogLocalState';
import { DiverPurchasePaymentEditorFormBookingAndAgency } from './DiverPurchasePaymentEditorFormBookingAndAgency';

const PURCHASE_PAYMENT_STATES_OPTIONS = PURCHASE_PAYMENT_STATES.filter(
  (x) => x !== 'authorized',
).map((type) => {
  const option: ValueLabel<PurchasePaymentState> = {
    label: purchasePaymentStateFormatter.formatPaymentState(type),
    value: type,
  };
  return option;
});

export const DiverPurchasePaymentEditorFormRHF = ({
  localState,
  className,
}: {
  localState: DiverPurchasePaymentEditorDialogLocalState;
  className?: string;
}) => {
  const { form, paymentChangesModel, inputState } = localState;
  const isCreation = inputState.mode === 'create';

  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;

  const paymentMethodOptions = useAppPaymentMethodsOptions();

  const bookingResumesLoaded = localState.data.bookingResumesLoaded;

  const mainCurrency = useAppCurrencyMain();

  const { control, setValue } = form;

  const [
    usedCurrencyIsoCode,
    usedCurrencyRate,
    usedCurrencyRateReverse,
    onlinePlatformId,
    bookingDepositId,
    paymentMethod,
    paymentState,
    paymentDate,
  ] = useWatch({
    control,
    name: [
      'usedCurrencyIsoCode',
      'usedCurrencyRate',
      'usedCurrencyRateReverse',
      'onlinePlatformId',
      'bookingDepositId',
      'paymentMethod',
      'paymentState',
      'paymentDate',
    ],
  });

  const availablePaymentCurrencySettings: ClubPaymentCurrencySettings[] =
    useMemo(
      () => clubSettings.general?.payments?.currencies ?? [],
      [clubSettings.general?.payments?.currencies],
    );
  const initialUsePaymentCurrencyInfo = useMemo<{
    currency: AppCurrency;
    currencySettings: ClubPaymentCurrencySettings;
  }>(() => {
    if (usedCurrencyIsoCode && usedCurrencyIsoCode !== mainCurrency?.isoCode) {
      const usedCurrency = APP_CURRENCIES.find(
        (x) => x.isoCode === usedCurrencyIsoCode,
      );

      const usedCurrencySettings = availablePaymentCurrencySettings.find(
        (x) => x.currencyIsoCode === usedCurrencyIsoCode,
      );
      return {
        currencySettings: usedCurrencySettings,
        currency: usedCurrency,
      };
    }
  }, [
    availablePaymentCurrencySettings,
    mainCurrency?.isoCode,
    usedCurrencyIsoCode,
  ]);

  const [usePaymentCurrencyInfo, setUsePaymentCurrencyInfo] = useState<{
    currency: AppCurrency;
    currencySettings: ClubPaymentCurrencySettings;
  }>(initialUsePaymentCurrencyInfo);

  const availablePaymentCurrencyIsoCodes: AppCurrencyIsoCode[] = useMemo(
    () => availablePaymentCurrencySettings.map((x) => x.currencyIsoCode),
    [availablePaymentCurrencySettings],
  );

  const availablePaymentCurrencies: AppCurrency[] = useAppCurrencies(
    ...availablePaymentCurrencyIsoCodes,
  );

  const paymentCurrenciesOptions = useAppCurrencyOptions(
    availablePaymentCurrencies,
  );

  const currencyConversionEnabled =
    clubSettings.general?.payments?.currencyConversionEnabled;

  const totalToPay = paymentChangesModel.totalToPay;
  const onChangeUsedCurrencyIsoCode = useCallback(
    (usedCurrencyIsoCode: AppCurrencyIsoCode) => {
      if (
        usedCurrencyIsoCode &&
        usedCurrencyIsoCode !== mainCurrency?.isoCode
      ) {
        const usedCurrency = availablePaymentCurrencies.find(
          (x) => x.isoCode === usedCurrencyIsoCode,
        );

        const usedCurrencySettings = availablePaymentCurrencySettings.find(
          (x) => x.currencyIsoCode === usedCurrencyIsoCode,
        );
        if (usedCurrencySettings) {
          setUsePaymentCurrencyInfo({
            currencySettings: usedCurrencySettings,
            currency: usedCurrency,
          });
          const defaultRate = usedCurrencySettings.defaultRate;
          const defaultRateReverse = usedCurrencySettings.defaultRateReverse;
          const usedCurrencyAmount = Math.round(
            defaultRateReverse
              ? totalToPay / defaultRate
              : totalToPay * defaultRate,
          );
          if (!isNaN(usedCurrencyAmount)) {
            setValue('usedCurrencyRate', defaultRate);
            setValue('usedCurrencyRateReverse', defaultRateReverse);
            setValue('usedCurrencyAmount', usedCurrencyAmount);
          }
        }
      } else {
        setValue('usedCurrencyRate', 1);
        setValue('usedCurrencyRateReverse', false);
        setValue('usedCurrencyAmount', totalToPay);
      }
    },
    [
      availablePaymentCurrencies,
      availablePaymentCurrencySettings,
      mainCurrency?.isoCode,
      setValue,
      totalToPay,
    ],
  );
  const onChangeUsedCurrencyAmount = useCallback(
    (usedCurrencyAmount: number) => {
      if (
        usedCurrencyIsoCode &&
        usedCurrencyIsoCode !== mainCurrency?.isoCode &&
        usePaymentCurrencyInfo
      ) {
        const usedCurrency = usePaymentCurrencyInfo.currency;
        const usedCurrencySettings = usePaymentCurrencyInfo.currencySettings;

        setUsePaymentCurrencyInfo({
          currencySettings: usedCurrencySettings,
          currency: usedCurrency,
        });
        const usedCurrencyRate =
          Math.round(
            10000 *
              (usedCurrencyRateReverse
                ? totalToPay / usedCurrencyAmount
                : usedCurrencyAmount / totalToPay),
          ) / 10000;
        if (!isNaN(usedCurrencyRate)) {
          setValue('usedCurrencyRate', usedCurrencyRate);
        }
      }
    },
    [
      mainCurrency?.isoCode,
      setValue,
      totalToPay,
      usePaymentCurrencyInfo,
      usedCurrencyIsoCode,
      usedCurrencyRateReverse,
    ],
  );

  const onChangeUsedCurrencyRate = useCallback(
    (usedCurrencyRate: number) => {
      if (
        usedCurrencyIsoCode &&
        usedCurrencyIsoCode !== mainCurrency?.isoCode &&
        usePaymentCurrencyInfo
      ) {
        const usedCurrency = usePaymentCurrencyInfo.currency;
        const usedCurrencySettings = usePaymentCurrencyInfo.currencySettings;

        setUsePaymentCurrencyInfo({
          currencySettings: usedCurrencySettings,
          currency: usedCurrency,
        });
        const usedCurrencyAmount = Math.round(
          usedCurrencyRateReverse
            ? totalToPay / usedCurrencyRate
            : totalToPay * usedCurrencyRate,
        );
        if (!isNaN(usedCurrencyAmount)) {
          setValue('usedCurrencyAmount', usedCurrencyAmount);
        }
      }
    },
    [
      mainCurrency?.isoCode,
      setValue,
      totalToPay,
      usePaymentCurrencyInfo,
      usedCurrencyIsoCode,
      usedCurrencyRateReverse,
    ],
  );

  const onChangeUsedCurrencyRateReverse = useCallback(
    (usedCurrencyRateReverse: boolean) => {
      if (
        usedCurrencyIsoCode &&
        usedCurrencyIsoCode !== mainCurrency?.isoCode &&
        usePaymentCurrencyInfo
      ) {
        const usedCurrency = usePaymentCurrencyInfo.currency;
        const usedCurrencySettings = usePaymentCurrencyInfo.currencySettings;

        setUsePaymentCurrencyInfo({
          currencySettings: usedCurrencySettings,
          currency: usedCurrency,
        });
        const usedCurrencyAmount = Math.round(
          usedCurrencyRateReverse
            ? totalToPay / usedCurrencyRate
            : totalToPay * usedCurrencyRate,
        );
        if (!isNaN(usedCurrencyAmount)) {
          setValue('usedCurrencyAmount', usedCurrencyAmount);
        }
      }
    },
    [
      mainCurrency?.isoCode,
      setValue,
      totalToPay,
      usePaymentCurrencyInfo,
      usedCurrencyIsoCode,
      usedCurrencyRate,
    ],
  );

  useEffect(
    () => {
      if (
        usedCurrencyIsoCode &&
        usedCurrencyIsoCode !== mainCurrency?.isoCode &&
        usePaymentCurrencyInfo
      ) {
        const usedCurrency = usePaymentCurrencyInfo.currency;
        const usedCurrencySettings = usePaymentCurrencyInfo.currencySettings;

        setUsePaymentCurrencyInfo({
          currencySettings: usedCurrencySettings,
          currency: usedCurrency,
        });
        const usedCurrencyAmount = Math.round(
          usedCurrencyRateReverse
            ? totalToPay / usedCurrencyRate
            : totalToPay * usedCurrencyRate,
        );
        if (!isNaN(usedCurrencyAmount)) {
          setValue('usedCurrencyAmount', usedCurrencyAmount);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      totalToPay, // seulement si le total change!
    ],
  );

  return (
    <div className={clsx('grid gap-4', className)}>
      {currencyConversionEnabled && isCreation && (
        <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4'}>
          <AppFormControlRHF
            className="w-full"
            label="Devise de paiement"
            control={control}
            name="usedCurrencyIsoCode"
            required={true}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={paymentCurrenciesOptions}
                onChange={onChangeUsedCurrencyIsoCode}
              />
            )}
          />
          {usedCurrencyIsoCode &&
            usedCurrencyIsoCode !== mainCurrency?.isoCode && (
              <>
                <AppFormControlRHF
                  className="w-full"
                  label={`Montant en ${usedCurrencyIsoCode}`}
                  control={control}
                  name="usedCurrencyAmount"
                  required={true}
                  renderComponent={(props) => (
                    <AppInputRHF
                      {...props}
                      type="number"
                      fullWidth
                      onChange={(value) =>
                        onChangeUsedCurrencyAmount(value as number)
                      }
                    />
                  )}
                />
                <AppFormControlRHF
                  // className="w-[10rem]"
                  label="Taux de change"
                  control={control}
                  name="usedCurrencyRate"
                  required={true}
                  renderComponent={(props) => (
                    <AppInputRHF
                      {...props}
                      type="number"
                      fullWidth
                      onChange={(value) =>
                        onChangeUsedCurrencyRate(value as number)
                      }
                    />
                  )}
                />
                <AppFormControlRHF
                  label="Sens du change"
                  control={control}
                  name={'usedCurrencyRateReverse'}
                  required={false}
                  renderComponent={(props) => (
                    <div className="flex gap-2 flex-wrap">
                      <AppInputBooleanSwitchRHF
                        {...props}
                        onChange={onChangeUsedCurrencyRateReverse}
                      />{' '}
                      {usePaymentCurrencyInfo && (
                        <AppCurrencyChangeLabel
                          className="font-medium text-app-primary self-center"
                          mainCurrency={mainCurrency}
                          paymentCurrency={usePaymentCurrencyInfo.currency}
                          defaultRate={usedCurrencyRate}
                          defaultRateReverse={usedCurrencyRateReverse}
                        />
                      )}
                    </div>
                  )}
                />
              </>
            )}
        </div>
      )}
      <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4'}>
        {onlinePlatformId ? (
          <div>
            <AppFormLabel label="Moyen de paiement" />
            <div className="text-sm text-status-warn font-medium">
              <span className="uppercase">
                {paymentMethodFormatter.formatPaymentMethod(paymentMethod)}
              </span>
              <span className="text-xs"> (en ligne)</span>
            </div>
          </div>
        ) : bookingDepositId ? (
          <div>
            <AppFormLabel label="Moyen de paiement" />
            <div className="text-sm text-status-warn font-medium">
              <span className="uppercase">ACOMPTE</span>
            </div>
          </div>
        ) : (
          <AppFormControlRHF
            className="w-full"
            label="Moyen de paiement"
            control={control}
            name="paymentMethod"
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={paymentMethodOptions}
              />
            )}
          />
        )}
        <AppFormControlRHF
          className="w-full"
          helpDescription="Ce lien apparaitra sur l'espace client tant que le paiement n'est pas confirmé"
          label={'Lien de paiement'}
          control={control}
          name="paymentLink"
          required={false}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <AppFormControlRHF
          className="w-full"
          label={
            onlinePlatformId || bookingDepositId
              ? 'Réference'
              : 'Réference (numéro de chèque...)'
          }
          control={control}
          name="paymentReference"
          required={false}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        {/* <AppFormControlRHF
          className="w-full"
          label="Réservation"
          control={control}
          name="bookingId"
          required={!!onlinePlatformId}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              theme={'material-ui'}
              {...props}
              options={bookingOptions}
            />
          )}
        /> */}
        {onlinePlatformId ? (
          <div>
            <AppFormLabel label="État du paiement" />
            <div className="text-sm text-gray-500 font-medium">
              <span className="uppercase">
                {purchasePaymentStateFormatter.formatPaymentState(paymentState)}
              </span>
            </div>
          </div>
        ) : bookingDepositId ? (
          <div>
            <AppFormLabel label="État du paiement" />
            <div className="text-sm text-gray-500 font-medium">
              <span className="uppercase">
                {purchasePaymentStateFormatter.formatPaymentState(paymentState)}
              </span>
            </div>
          </div>
        ) : (
          <AppFormControlRHF
            className="w-full"
            label="État du paiement"
            control={control}
            name="paymentState"
            required={true}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={PURCHASE_PAYMENT_STATES_OPTIONS}
                onChange={(value) => {
                  // FIXME ne fonctionne pas, donc on gère ça via un hack:
                  // à l'init du formulaire, on renseigne toujours la date de paiement si elle est vide
                  // à l'enregistrement, on la vide si l'état est pending
                  //   const paymentState = value as PurchasePaymentState;
                  // if (paymentState === 'pending') {
                  //   setValue('paymentDate', null);
                  // }
                  // if (paymentState === 'validated' && !paymentDate) {
                  //  setValue(
                  //     'paymentDate',
                  //     dateService.getUTCDateWithoutTimeFromLocalTime(
                  //       new Date(),
                  //     ),
                  //   );
                  // }
                }}
              />
            )}
          />
        )}
        {paymentState === 'pending' ? (
          <AppFormControlRHF
            className="w-full"
            label="Date d'échéance"
            control={control}
            name="dueDate"
            required={false}
            renderComponent={(props) => (
              <AppInputDatePickerMaterialRHF className="w-full" {...props} />
            )}
          />
        ) : (
          !onlinePlatformId && (
            <AppFormControlRHF
              key={(paymentDate ?? new Date()).getTime()}
              className="w-full"
              label="Date de paiement"
              control={control}
              name="paymentDate"
              required={paymentState === 'validated'}
              renderComponent={(props) => (
                <AppInputDatePickerMaterialRHF className="w-full" {...props} />
              )}
            />
          )
        )}
      </div>

      <DiverPurchasePaymentEditorFormBookingAndAgency
        isCreation={isCreation}
        form={form}
        initialBookingId={localState?.initialFormValue?.bookingId}
        aggregatedData={localState?.data?.aggregatedData}
      />

      <div className="grid gap-4 lg:grid-cols-2">
        <AppFormControlRHF
          className="w-full"
          label="Commentaire privé 🚫"
          control={control}
          name="comment"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />{' '}
        <AppFormControlRHF
          className="w-full"
          label="Commentaire client 👨‍🦰👩‍🦰"
          control={control}
          name="customerComment"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
    </div>
  );
};
