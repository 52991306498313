/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubProductPackageNameDetails } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { ClubSettingsProductOffersFormModel } from '../_model';

export const ClubSettingsProductOffersFormOfferCustomName = ({
  form,
  i,
  nameDetails,
  className,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  i: number;
  nameDetails: ClubProductPackageNameDetails;
  className?: string;
}) => {
  const { control, setValue } = form;

  const [diveMode, overrideDefaultNameEnabled, includeDefaultName, label] =
    useWatch({
      control,
      name: [
        `offers.${i}.productPackage.diveAttributes.diveMode`,
        `offers.${i}.productPackage.namingConfiguration.overrideDefaultName.enabled`,
        `offers.${i}.productPackage.namingConfiguration.includeDefaultName`,
        `offers.${i}.productPackage.label`,
      ],
    });
  const isExplo =
    diveMode === 'autonomous' ||
    diveMode === 'autoSupervised' ||
    diveMode === 'supervised' ||
    diveMode === 'instructor';

  return (
    <div className={clsx('flex flex-wrap gap-4', className)}>
      <div>
        <AppFormControlRHF_Deprecated
          label={'Nom personnalisé'}
          control={control}
          name={`offers.${i}.productPackage.namingConfiguration.overrideDefaultName.enabled`}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              onChange={(overrideDefaultNameEnabled) => {
                if (overrideDefaultNameEnabled) {
                  setValue(
                    `offers.${i}.productPackage.namingConfiguration.overrideDefaultName.prefix`,
                    nameDetails.prefix,
                  );
                  setValue(
                    `offers.${i}.productPackage.namingConfiguration.overrideDefaultName.name`,
                    nameDetails.name,
                  );
                  setValue(
                    `offers.${i}.productPackage.namingConfiguration.overrideDefaultName.label`,
                    nameDetails.label,
                  );
                  setValue(
                    `offers.${i}.productPackage.namingConfiguration.overrideDefaultName.suffix`,
                    nameDetails.suffix,
                  );
                }
              }}
            />
          )}
        />
      </div>
      {overrideDefaultNameEnabled ? (
        <div className="flex-grow flex flex-wrap gap-2">
          <AppFormControlRHF_Deprecated
            control={control}
            label="Diminutif"
            name={`offers.${i}.productPackage.namingConfiguration.overrideDefaultName.prefix`}
            disabled={!overrideDefaultNameEnabled}
            validation={{
              rules: {
                maxLength: 6,
              },
            }}
            required={overrideDefaultNameEnabled}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          <AppFormControlRHF_Deprecated
            control={control}
            label="Nom (bleu)"
            name={`offers.${i}.productPackage.namingConfiguration.overrideDefaultName.name`}
            disabled={!overrideDefaultNameEnabled}
            required={overrideDefaultNameEnabled}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          <AppFormControlRHF_Deprecated
            control={control}
            label="Complément (orange)"
            name={`offers.${i}.productPackage.namingConfiguration.overrideDefaultName.label`}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          <AppFormControlRHF_Deprecated
            control={control}
            label="Suffixe"
            name={`offers.${i}.productPackage.namingConfiguration.overrideDefaultName.suffix`}
            disabled={!overrideDefaultNameEnabled}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        </div>
      ) : (
        <>
          <AppFormControlRHF_Deprecated
            label="Libellé additionnel"
            control={control}
            name={`offers.${i}.productPackage.label`}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          {isExplo && (
            <div className="flex gap-2 items-end">
              {label?.length > 0 && (
                <AppFormControlRHF_Deprecated
                  label={'Nommage'}
                  control={control}
                  name={`offers.${i}.productPackage.namingConfiguration.includeDefaultName`}
                  renderComponent={(props) => (
                    <AppInputBooleanRHF
                      {...props}
                      type="checkbox"
                      description={'inclure le nom par défaut'}
                    />
                  )}
                />
              )}
              {includeDefaultName &&
                (diveMode === 'supervised' || diveMode === 'autonomous') && (
                  <AppFormControlRHF_Deprecated
                    control={control}
                    name={`offers.${i}.productPackage.namingConfiguration.includeSupervision`}
                    renderComponent={(props) => (
                      <AppInputBooleanRHF
                        {...props}
                        type="checkbox"
                        description={'inclure l\'encadrement'}
                      />
                    )}
                  />
                )}
              {includeDefaultName &&
                (diveMode === 'supervised' || diveMode === 'autonomous') && (
                  <AppFormControlRHF_Deprecated
                    control={control}
                    name={`offers.${i}.productPackage.namingConfiguration.includeEquipment`}
                    renderComponent={(props) => (
                      <AppInputBooleanRHF
                        {...props}
                        type="checkbox"
                        description={'inclure l\'équipement'}
                      />
                    )}
                  />
                )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
