/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_CURRENCIES } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyOptions,
  useAppMonthIndexOptions,
} from 'src/pages/_components/options';
import { ClubSettingsGeneralFormModel } from '../../../_model';

export const ClubSettingsGeneralPurchaseEditFormOptions = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const diveCenterResume = useDiveCenterResume();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  const currencyOptions = useAppCurrencyOptions(APP_CURRENCIES);
  const appMonthIndexOptions = useAppMonthIndexOptions();

  const [billingEnabled, billingBookingEnabled] = useWatch({
    control,
    name: [
      'clubSettings.general.billing.enabled',
      'clubSettings.general.billing.booking.enabled',
    ],
  });

  return (
    <ClubSettingsSection title="Options">
      <div className="grid gap-y-2 gap-x-4 xl:grid-cols-2">
        <div className="flex flex-col gap-y-2">
          <AppFormControlRHF_Deprecated
            control={control}
            name="clubSettings.general.billing.showDepth"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                margin="mr-2.5"
                description={
                  'Afficher la profondeur de plongée des encadrés sur l\'onglet de facturation'
                }
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            control={control}
            name="clubSettings.general.billing.showDistance"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                margin="mr-2.5"
                {...props}
                type="checkbox"
                description={
                  'Afficher les distance des sites de plongées sur l\'onglet de facturation'
                }
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            control={control}
            name="clubSettings.general.billing.showResidentType"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                margin="mr-2.5"
                {...props}
                type="checkbox"
                description={
                  'Afficher le statut de résident ou vacancier sur l\'onglet de facturation'
                }
              />
            )}
          />
          <div className="flex gap-2">
            <AppFormControlRHF_Deprecated
              control={control}
              name={'clubSettings.general.billing.booking.enabled'}
              required={false}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  margin="mr-2.5"
                  description={'Associer à une réservation'}
                />
              )}
            />
            {billingBookingEnabled && (
              <AppFormControlRHF_Deprecated
                control={control}
                name={'clubSettings.general.billing.booking.required'}
                required={false}
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    margin="mr-2.5"
                    description={'Obligatoire'}
                  />
                )}
              />
            )}
          </div>

          {clubSettings.general?.agencies?.enabled && (
            <AppFormControlRHF_Deprecated
              control={control}
              name={'clubSettings.general.agencies.agencyPurchase'}
              required={false}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  margin="mr-2.5"
                  description={'Facturation par une agence'}
                />
              )}
            />
          )}
          {clubSettings.general?.agencies?.enabled && (
            <AppFormControlRHF_Deprecated
              control={control}
              name={'clubSettings.general.agencies.agencyPayment'}
              required={false}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  margin="mr-2.5"
                  description={'Paiement à une agence'}
                />
              )}
            />
          )}
        </div>
        {isSuperAdmin && (
          <>
            <div className="flex flex-col gap-y-2">
              <AppFormControlRHF_Deprecated
                control={control}
                name="clubSettings.general.billing.explorations.groupsCriteria.distance"
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    margin="mr-2.5"
                    {...props}
                    type="checkbox"
                    description={
                      'Grouper les explorations par distance du site de plongée'
                    }
                  />
                )}
              />
              <AppFormControlRHF_Deprecated
                control={control}
                name="clubSettings.general.billing.explorations.groupsCriteria.groupAutonomousAndSupervised"
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    margin="mr-2.5"
                    {...props}
                    type="checkbox"
                    description={'Grouper les autonomes et encadrés'}
                  />
                )}
              />
              <AppFormControlRHF_Deprecated
                control={control}
                name="clubSettings.general.billing.explorations.groupsCriteria.multiSessions"
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    margin="mr-2.5"
                    {...props}
                    type="checkbox"
                    description={
                      'Grouper les explorations par plongée simple ou double-bloc'
                    }
                  />
                )}
              />
              <AppFormControlRHF_Deprecated
                control={control}
                name={'clubSettings.general.billing.thirdPartyCollectEnabled'}
                required={false}
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    margin="mr-2.5"
                    description={'Encaissement pour le compte d\'un tiers'}
                  />
                )}
              />
              <AppFormControlRHF_Deprecated
                className="col-start-1"
                label={'Tarif fixe plongée successive N°2'}
                control={control}
                name="clubSettings.general.billing.explorations.pricing.session2FixedPrice"
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
            </div>
          </>
        )}
      </div>
    </ClubSettingsSection>
  );
};
