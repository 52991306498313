/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanSwitchRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { BookingCustomerConfigEditFormModel } from '../../_model';

// note, copié/collé de CustomerSpaceConfigFormCustomerUpdate
export const BookingCustomerConfigEditFormCustomerUpdate = ({
  form,
}: {
  form: UseFormReturn<BookingCustomerConfigEditFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isDiversPostalAddressInfoEnabledByDefault =
    clubReference === 'la-bulle-d-her-noirmoutier-27455';

  const formPrefix = 'booking.bookingCustomerConfig' as const;

  const [
    customerUiEnableCustomerUpdate,
    customerUiPendingListDiversContactInfo,
    customerUiPendingListDiversDivingInfo,
    customerUiPendingListDiversDiversPostalAddressInfo,
    customerUiPendingListDiversExpectedDiveInfo,
  ] = useWatch({
    control,
    name: [
      `${formPrefix}.customerUi.enableCustomerUpdate`,
      `${formPrefix}.pendingList.diversContactInfo`,
      `${formPrefix}.pendingList.diversDivingInfo`,
      `${formPrefix}.pendingList.diversPostalAddressInfo`,
      `${formPrefix}.pendingList.diversExpectedDiveInfo`,
    ],
  });

  const customerUpdateRequested =
    customerUiPendingListDiversContactInfo ||
    customerUiPendingListDiversDivingInfo ||
    customerUiPendingListDiversDiversPostalAddressInfo ||
    customerUiPendingListDiversExpectedDiveInfo;
  useEffect(() => {
    if (!customerUpdateRequested && customerUiEnableCustomerUpdate) {
      setValue(`${formPrefix}.customerUi.enableCustomerUpdate`, null);
    }
  });

  return (
    <div>
      <AppFormControlRHF
        className={'md:col-span-4 w-full'}
        label="Fiche à renseigner"
        labelFontSize="text-md"
        helpDescription="En activant cette option, vous autorisez le client à renseigner lui-même les informations de ses plongeurs."
        control={control}
        name={`${formPrefix}.customerUi.enableCustomerUpdate`}
        renderComponent={(props) => (
          <AppInputBooleanSwitchRHF
            {...props}
            theme="danger"
            label="Demander des informations au client"
            onChange={(value) => {
              if (value && !customerUpdateRequested) {
                setValue(`${formPrefix}.pendingList.diversContactInfo`, true);
                setValue(`${formPrefix}.pendingList.diversDivingInfo`, true);
                setValue(
                  `${formPrefix}.pendingList.diversPostalAddressInfo`,
                  isDiversPostalAddressInfoEnabledByDefault,
                );
                setValue(
                  `${formPrefix}.pendingList.diversExpectedDiveInfo`,
                  false,
                );
              }
            }}
          />
        )}
      />
      {customerUiEnableCustomerUpdate && (
        <div>
          <p className="mt-5 mb-2 text-gray-500 text-xs uppercase">
            Informations à renseigner par le client:
          </p>
          <AppFormControlRHF
            className="my-1 w-full xs:col-span-6 sm:col-span-6"
            control={control}
            name={`${formPrefix}.pendingList.diversContactInfo`}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                className="w-full"
                {...props}
                label={'coordonnees des participants'}
                label2={'Nom, prénom, téléphone...'}
                onChange={(value) => {
                  if (value) {
                    setValue(
                      `${formPrefix}.customerUi.enableCustomerUpdate`,
                      value,
                    );
                  }
                }}
              />
            )}
          />
          <AppFormControlRHF
            className="my-1 w-full xs:col-span-6 sm:col-span-6"
            control={control}
            name={`${formPrefix}.pendingList.diversDivingInfo`}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                className="w-full"
                {...props}
                label={'caractéristiques des plongeurs'}
                label2={'Niveau, dernière plongée, certificat médical...'}
                onChange={(value) => {
                  if (value) {
                    setValue(
                      `${formPrefix}.customerUi.enableCustomerUpdate`,
                      value,
                    );
                  } else {
                    setValue(
                      `${formPrefix}.pendingList.diversExpectedDiveInfo`,
                      false,
                    );
                  }
                }}
              />
            )}
          />
          {customerSettings?.general?.homeAddress && (
            <AppFormControlRHF
              className="my-1 w-full xs:col-span-6 sm:col-span-6"
              control={control}
              name={`${formPrefix}.pendingList.diversPostalAddressInfo`}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF
                  className="w-full"
                  {...props}
                  label={'adresse postale'}
                  onChange={(value) => {
                    if (value) {
                      setValue(
                        `${formPrefix}.customerUi.enableCustomerUpdate`,
                        value,
                      );
                    }
                  }}
                />
              )}
            />
          )}

          <AppFormControlRHF
            className="my-1 w-full xs:col-span-6 sm:col-span-6"
            control={control}
            name={`${formPrefix}.pendingList.diversExpectedDiveInfo`}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                className="w-full"
                {...props}
                label={'prestations souhaitées'}
                label2={'Baptềme, explo...'}
                onChange={(value) => {
                  if (value) {
                    setValue(
                      `${formPrefix}.pendingList.diversDivingInfo`,
                      true,
                    );
                    setValue(
                      `${formPrefix}.customerUi.enableCustomerUpdate`,
                      value,
                    );
                  }
                }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};
