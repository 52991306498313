/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppButtonsBar,
  AppLoader,
  AppPageContainerWithFixedBars,
  AppTabsBar,
} from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { useSideMenu } from 'src/business/_core/modules/layout/components/SideMenu/useSideMenu.hook';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useAppSecurityUser } from 'src/business/auth/services';
import { AppFormControlV2, AppSingleAutocomplete2 } from 'src/lib/form';
import { useDiversOptions } from 'src/pages/_components/options';
import { ClubDialogsStateOld, useClubDialogsOld } from 'src/pages/_dialogs';
import { ValueLabel } from '../../../_common/form/components/ValueLabel.model';
import { useParticipantBackUrl } from '../../../url-builders';
import { GenericNavigationContext } from '../../ClubParticipantNavigationContext';
import { DiverBookingPageDialogsProvider } from './DiverBookingPageDialogsProvider';
import {
  DIVER_BOOKING_TABS,
  DiverBookingPageTabId,
} from './components/DiverBookingPageTab';
import {
  DiverBookingPageBillingTab,
  DiverBookingPageBookingTab,
  DiverBookingPagePaymentTab,
} from './tabs';
import { DiverBookingPageDiverSheet } from './tabs/DiverBookingPageDiverSheet';
import { DiverBookingPageMessagingTab } from './tabs/DiverBookingPageMessagingTab';
import { DiverBookingPageSessionsHistoryTab } from './tabs/DiverBookingPageSessionsHistoryTab';
import {
  DiverBookingPageActions,
  useDiverBookingPageActions,
} from './useDiverBookingPageActions.hook';
import {
  DiverBookingPageGlobalState,
  useDiverBookingPageGlobalState,
} from './useDiverBookingPageGlobalState.hook';
import { useDiverBookingPageInitialTriggerAction } from './useDiverBookingPageInitialTriggerAction.hook';
import { useDiverBookingPageTriggerAction } from './useDiverBookingPageTriggerAction.hook';

export const DiverBookingPage = ({
  navigationContext,
}: {
  navigationContext: GenericNavigationContext;
}) => {
  const redirectTo = useRedirect();

  const { diverId: mainDiverId, diveSessionReference } = navigationContext;

  const backUrl = useParticipantBackUrl({
    navigationContext,
    // sessionTabId: 'billing', (billing pas dispo pour tous les clubs, il faudrait se baser sur le rôle!)
  });

  const user = useAppSecurityUser();

  const dialogsOLD: ClubDialogsStateOld = useClubDialogsOld();

  const globalState: DiverBookingPageGlobalState =
    useDiverBookingPageGlobalState({
      navigationContext,
      dialogsOLD,
    });

  const { loadableContent, loadedContent, state } = globalState;

  const tab = state.viewState?.tab ?? 'booking';
  const selectTab = useCallback(
    (selectTab: DiverBookingPageTabId) => {
      state.setViewState({
        ...state.viewState,
        tab: selectTab,
      });
    },
    [state],
  );

  useEffect(() => {
    if (
      loadableContent.lastActionStatus === 'success' &&
      loadableContent.contentState === 'full'
    ) {
      if (loadedContent?.clubDivers.length === 0) {
        appLogger.warn(
          '[DiverBookingPage] diver not found, redirect back',
          loadedContent,
        );
        redirectTo(backUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadableContent.contentState, loadableContent.lastActionStatus]);

  const {
    updateState,
    setUpdateStateInner,
    setUpdateState,
    isPersistInProgress,
    setIsPersistInProgress,
    clubReference,
    focus,
    aggregatedData,
    triggerAction,
    setTriggerAction,
  } = globalState;

  const actions: DiverBookingPageActions = useDiverBookingPageActions({
    globalState,
    dialogs: dialogsOLD,
  });

  useEffect(() => {
    // initialisation de updateState avec les données chargées
    if (!updateState.isInitialized && loadableContent.contentState === 'full') {
      setUpdateStateInner({
        ...updateState,
        isInitialized: true,
        hasChanges: false,
      });
    }
  }, [loadableContent.contentState, setUpdateStateInner, updateState]);

  useDiverBookingPageTriggerAction({
    globalState,
    dialogs: dialogsOLD,
    actions,
    triggerAction,
    setTriggerAction,
  });
  useDiverBookingPageInitialTriggerAction({
    globalState,
    dialogs: dialogsOLD,
    actions,
    isContentLoaded: loadableContent.contentState === 'full',
    setTriggerAction,
  });

  const { disableMenu } = useSideMenu();
  const back = useCallback(() => {
    disableMenu(false);
    redirectTo(backUrl);
  }, [backUrl, disableMenu, redirectTo]);

  const persistChangesAndBack = useCallback(async () => {
    if (!updateState.hasChanges || isPersistInProgress) {
      back();
    } else {
      const isSuccess = await globalState.actions.persistChanges(
        'persistChangesAndBack',
      );

      if (isSuccess) {
        back();
      }
    }
  }, [back, globalState.actions, isPersistInProgress, updateState.hasChanges]);

  const diversFilterOptions: ValueLabel<string>[] = useDiversOptions(
    aggregatedData.diversLoaded,
  );

  return (
    <DiverBookingPageDialogsProvider
      globalState={globalState}
      dialogs={dialogsOLD}
      actions={actions}
    >
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-gray-50"
        {...loadableContent}
        headerBar={() => (
          <AppTabsBar
            theme="participant"
            tabs={DIVER_BOOKING_TABS}
            context={{ diverId: mainDiverId, diveSessionReference }}
            value={tab}
            onChange={(tab) => selectTab(tab.id)}
            // disableNavigation={globalState?.updateState.hasChanges}
          >
            {diversFilterOptions.length > 1 && (
              <div className="relative hidden md:block flex-grow self-end text-right mb-2 mx-3 min-w-[170px] lg:min-w-[300px]">
                <label className="absolute left-0 -top-4 text-gray-50 text-xs uppercase">
                  Filtrer par participant
                </label>
                <div className="w-full inline-block px-2 bg-white rounded p-2">
                  <AppSingleAutocomplete2
                    className="w-full"
                    fullWidth={true}
                    options={diversFilterOptions}
                    value={updateState.filteredDiverId}
                    onChange={(filteredDiverId) => {
                      setUpdateState(
                        {
                          ...updateState,
                          filteredDiverId,
                        },
                        {
                          action: 'filter by participant',
                          meta: {
                            filteredDiverId,
                          },
                        },
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </AppTabsBar>
        )}
        footerBar={() => (
          <>
            {diversFilterOptions.length > 1 && (
              <div className="md:hidden w-full my-3 px-2 flex">
                <div className="flex-grow bg-white rounded p-2">
                  <AppFormControlV2
                    label={'Filtrer par participant'}
                    renderComponent={() => (
                      <AppSingleAutocomplete2
                        className="w-full"
                        fullWidth={true}
                        options={diversFilterOptions}
                        value={updateState.filteredDiverId}
                        onChange={(filteredDiverId) => {
                          setUpdateState(
                            {
                              ...updateState,
                              filteredDiverId,
                            },
                            {
                              action: 'filter by participant',
                              meta: {
                                filteredDiverId,
                              },
                            },
                          );
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <AppButtonsBar className="" hasChanges={updateState.hasChanges}>
              {updateState.hasChanges && user.roles.includes('club-edit') ? (
                <>
                  <AppButton
                    size="normal"
                    icon={AppHeroIcons.actionCancel}
                    tabIndex={500}
                    color="gray-outline-light"
                    onClick={() => {
                      if (updateState.hasChanges) {
                        confirmDialog
                          .confirm({
                            isTailwind: true,
                            title: 'Annuler mes modifications',
                            message:
                              'Vous avez des modifications en cours, êtes-vous sûr de vouloir les annuler?',
                            type: 'cancel',
                          })
                          .subscribe((confirmed: boolean) => {
                            if (confirmed) {
                              back();
                            }
                          });
                      } else {
                        back();
                      }
                    }}
                  >
                    Annuler
                  </AppButton>
                  <AppButton
                    className="uppercase font-bold"
                    disabled={isPersistInProgress}
                    size="normal"
                    icon={AppHeroIcons.actionConfirm}
                    color="primary-outline-light"
                    onClick={() =>
                      !isPersistInProgress && persistChangesAndBack()
                    }
                  >
                    Enregistrer
                  </AppButton>
                </>
              ) : (
                <AppButton
                  className="uppercase max-w-xs"
                  fullWidth={true}
                  size="normal"
                  icon={AppHeroIcons.actionClose}
                  color="gray-outline-light"
                  tabIndex={500}
                  onClick={() => {
                    back();
                  }}
                >
                  Fermer
                </AppButton>
              )}
            </AppButtonsBar>
          </>
        )}
      >
        {!!isPersistInProgress ? (
          <div className="app-p-content">
            <AppLoader type="persist" />
          </div>
        ) : (
          <>
            {updateState.isInitialized && (
              <>
                {tab === 'edit-participant' && (
                  <DiverBookingPageDiverSheet
                    globalState={globalState}
                    dialogs={dialogsOLD}
                    actions={actions}
                  />
                )}
                {tab === 'booking' && (
                  <DiverBookingPageBookingTab
                    globalState={globalState}
                    dialogs={dialogsOLD}
                    actions={actions}
                  />
                )}
                {tab === 'booking-sessions-history' && (
                  <DiverBookingPageSessionsHistoryTab
                    globalState={globalState}
                    dialogs={dialogsOLD}
                    actions={actions}
                  />
                )}
                {tab === 'billing' && (
                  <DiverBookingPageBillingTab
                    globalState={globalState}
                    dialogs={dialogsOLD}
                    actions={actions}
                  />
                )}
                {tab === 'payment' && (
                  <DiverBookingPagePaymentTab
                    globalState={globalState}
                    dialogs={dialogsOLD}
                    actions={actions}
                  />
                )}
                {tab === 'messaging' && (
                  <DiverBookingPageMessagingTab
                    globalState={globalState}
                    dialogs={dialogsOLD}
                    actions={actions}
                  />
                )}
              </>
            )}
          </>
        )}
      </AppPageContainerWithFixedBars>
    </DiverBookingPageDialogsProvider>
  );
};
