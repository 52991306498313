import React, { useMemo } from 'react';
import { PRO_BookingResume } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { useClubSettings } from '../../../../../data/hooks';
import { DiveCenterBookingMembersListDiffItem } from './DiveCenterBookingMembersListDiffItem';
import {
  BookingResumeOnClickMemberAction,
  DiveCenterBookingMemberDiff,
} from './model';

// Note: composant copié et adapté à partir de "DiveCenterBookingMembersList" sur l'espace plongeur
export function DiveCenterBookingMembersDiffList({
  aggregatedBookingResume,
  membersDiff,
  onClick,
  className,
}: {
  aggregatedBookingResume: PRO_BookingResume;
  membersDiff: DiveCenterBookingMemberDiff[];
  onClick: (
    memberDiff: DiveCenterBookingMemberDiff,
    options: {
      action: BookingResumeOnClickMemberAction;
      participantsIndex: number;
    },
  ) => any;
  className?: string;
}) {
  const clubSettings = useClubSettings();
  const showProfilePhoto = useMemo(() => {
    if (clubSettings?.customer?.general?.profilePhoto) {
      // at least one profile with photo

      return (
        membersDiff.find((x) => x.final?.diver?.details?.profilePhoto?._id) !==
        undefined
      );
    }
    return false;
  }, [clubSettings?.customer?.general?.profilePhoto, membersDiff]);

  return (
    <>
      <div className={`overflow-hidden text-xs ${className}`}>
        <ul role="list" className="divide-y divide-gray-200">
          {membersDiff.map((memberDiff, participantsIndex) => (
            <DiveCenterBookingMembersListDiffItem
              showProfilePhoto={showProfilePhoto}
              key={`${memberDiff.original?.diver?._id}-${memberDiff.final?.diver?._id}`}
              aggregatedBookingResume={aggregatedBookingResume}
              memberDiff={memberDiff}
              onClick={(memberDiff, action) => {
                onClick && onClick(memberDiff, { action, participantsIndex });
              }}
            />
          ))}
        </ul>
      </div>
    </>
  );
}
