/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSession } from '@mabadive/app-common-model';
import { useCallback, useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  useAppSecurityUserClubAuthorizations,
  useAppSecurityUserHasRoles,
} from '../../../../../../auth/services';
import {
  BookingResumeOnClickMemberAction,
  DiverBookingCardBookingAction,
} from '../../_from-diver-app';
import {
  AggregatedBookingSessionFull,
  BookingParticipantEditorDiverMember,
  BookingParticipantEditorParticipant,
  DiverBookingPageAggregatedDataCore,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
} from '../models';
import { DiverBookingPageUpdateState } from '../models/02.update-state';
import { BookingTabModel } from '../models/05.view-state';
import {
  useDiverBookingPageCreateParticipantQuick,
  useDiverBookingPageDeleteBooking,
  useDiverBookingPageDeleteMember,
  useDiverBookingPageDuplicateBooking,
  useDiverBookingPageExtractMemberToNewBooking,
  useDiverBookingPageUpdateBooking,
} from '../services';
import {
  DiverBookingPageGlobalState,
  DiverBookingPageSetUpdateStateFn,
} from '../useDiverBookingPageGlobalState.hook';
import { useDiverBookingCardHeaderAutoFix } from './DiverBookingCard';

export function useDiverBookingPageBookingCardLocalState({
  globalState,
  dialogs,
  bookingTabModel,
  aggregatedData,
  bookingTabModels,
  updateState,
  setUpdateState,
  openSessionDialog,
  openDiverSelector,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  bookingTabModel: BookingTabModel;
  aggregatedData: DiverBookingPageAggregatedDataCore;
  bookingTabModels: BookingTabModel[];
  updateState: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  openSessionDialog: ({ diveSession }: { diveSession: DiveSession }) => void;
  openDiverSelector: ({ bookingId }: { bookingId: string }) => void;
}) {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;
  const az = useAppSecurityUserClubAuthorizations();

  const { setBookingParticipantEditorState } = dialogs;

  const createParticipantQuick = useDiverBookingPageCreateParticipantQuick({
    setUpdateState,
    updateState,
    setBookingParticipantEditorState,
    diveCenterResume,
  });

  const onClickParticipant = useCallback(
    ({
      bookingSessionFull,
      bookingParticipantFull,
      bookingMemberFull,
      bookingTabModel,
    }: {
      bookingSessionFull: AggregatedBookingSessionFull;
      bookingParticipantFull: PRO_BookingParticipantFull;
      bookingMemberFull: PRO_BookingMemberFull_WithDocs;
      bookingTabModel: BookingTabModel;
    }) => {
      if (!bookingParticipantFull) {
        createParticipantQuick({
          bookingSessionFull,
          bookingMemberFull,
          bookingTabModel,
        });
      } else {
        const participant: BookingParticipantEditorParticipant =
          buildParticipantEditorEditOpenInputState({
            bookingParticipantFull,
          });
        setBookingParticipantEditorState({
          isOpen: true,
          mode: 'edit-participant',
          participant,
        });
      }
    },
    [createParticipantQuick, setBookingParticipantEditorState],
  );

  const extractMemberToNewBooking =
    useDiverBookingPageExtractMemberToNewBooking({
      aggregatedData,
      clubReference,
      diveCenterId,
      updateState,
      setUpdateState,
    });

  const deleteBooking = useDiverBookingPageDeleteBooking({
    clubReference,
    updateState,
    setUpdateState,
  });

  const deleteMember = useDiverBookingPageDeleteMember({
    updateState,
    setUpdateState,
  });
  const duplicateBooking = useDiverBookingPageDuplicateBooking({
    clubReference,
    updateState,
    setUpdateState,
  });

  const updateBooking = useDiverBookingPageUpdateBooking({
    updateState,
    setUpdateState,
  });

  const onClickBooking = useCallback(
    ({
      bookingTabModel,
      action,
    }: {
      bookingTabModel: BookingTabModel;
      action: DiverBookingCardBookingAction;
    }) => {
      if (action === 'delete-booking') {
        deleteBooking({
          booking: bookingTabModel,
        });
      } else if (action === 'confirm-booking') {
        updateBooking({
          booking: bookingTabModel,
          attributesToUpdate: {
            bookingStatus: 'confirmed',
          },
        });
      } else if (action === 'archive-booking') {
        updateBooking({
          booking: bookingTabModel,
          attributesToUpdate: {
            active: false,
          },
        });
      } else if (action === 'unarchive-booking') {
        updateBooking({
          booking: bookingTabModel,
          attributesToUpdate: {
            active: true,
          },
        });
      }
    },
    [deleteBooking, updateBooking],
  );
  const onClickMember = useCallback(
    ({
      bookingMemberFull,
      action,
    }: {
      bookingMemberFull: PRO_BookingMemberFull_WithDocs;
      action: BookingResumeOnClickMemberAction;
    }) => {
      if (action === 'edit') {
        const participant: BookingParticipantEditorDiverMember = {
          bookingId: bookingMemberFull.booking._id,
          diver: bookingMemberFull.diver,
          bookingMember: bookingMemberFull.bookingMember,
          bookingSessionId: undefined,
          diveSession: undefined,
          docResumes: bookingMemberFull.docResumes,
          inquiryResponses: bookingMemberFull.inquiryResponses,
        };
        setBookingParticipantEditorState({
          isOpen: true,
          mode: 'edit-diver',
          participant,
          defaultExpandedPanel: 'none',
        });
      } else if (action === 'extract-from-booking') {
        extractMemberToNewBooking({
          bookingSource: bookingTabModel,
          diverId: bookingMemberFull.bookingMember.diverId,
        });
      } else if (action === 'delete-member-with-participants') {
        if (bookingTabModel.aggregatedBooking.bookingMembers.length === 1) {
          // dernier participant: on supprime la résa
          deleteBooking({
            booking: bookingTabModel,
          });
        } else {
          deleteMember({
            bookingSource: bookingTabModel,
            diverId: bookingMemberFull.bookingMember.diverId,
          });
        }
      } else if (action === 'set-booking-contact') {
        updateBooking({
          booking: bookingTabModel,
          attributesToUpdate: {
            bookingContactDiverId: bookingMemberFull.bookingMember.diverId,
          },
        });
      } else if (action === 'add') {
        openDiverSelector({
          bookingId: bookingMemberFull.bookingMember.bookingId,
        });
      }
    },
    [
      setBookingParticipantEditorState,
      extractMemberToNewBooking,
      bookingTabModel,
      deleteBooking,
      deleteMember,
      updateBooking,
      openDiverSelector,
    ],
  );
  const onClickSession = useCallback(
    (bookingSessionFull: AggregatedBookingSessionFull) => {
      openSessionDialog({
        diveSession: bookingSessionFull.diveSession,
      });
      globalState.setSelectedDiveSessionReference(
        bookingSessionFull.diveSession.reference,
      );
    },
    [globalState, openSessionDialog],
  );

  const isHidden = useMemo(() => {
    return (
      updateState.filteredDiverId &&
      !bookingTabModel.aggregatedBooking.bookingMembers.find(
        (bm) => bm.diverId === updateState.filteredDiverId,
      )
    );
  }, [
    bookingTabModel.aggregatedBooking.bookingMembers,
    updateState.filteredDiverId,
  ]);
  const isEditParticipantRole = useAppSecurityUserHasRoles(
    'club-edit-participant-diver',
    'club-edit-participant-booking',
  );

  const enableAutoFix = isEditParticipantRole;

  const autoFixes = useDiverBookingCardHeaderAutoFix({
    bookingTabModel,
    enableAutoFix,
  });

  return {
    state: {
      ...globalState.state,
      isHidden,
      setBookingParticipantEditorState,
      autoFixes,
    },
    // data: {
    //   mergeableBookings,
    // },
    actions: {
      ...globalState.actions,
      createParticipantQuick,
      onClickParticipant,
      extractMemberToNewBooking,
      deleteBooking,
      deleteMember,
      duplicateBooking,
      updateBooking,
      onClickBooking,
      onClickMember,
      onClickSession,
    },
  };
}

export function buildParticipantEditorEditOpenInputState({
  bookingParticipantFull,
}: {
  bookingParticipantFull: PRO_BookingParticipantFull;
}): BookingParticipantEditorParticipant {
  if (!bookingParticipantFull) {
    return undefined;
  }
  const bookingId = bookingParticipantFull.booking._id;

  const {
    diver,
    bookingSession,
    bookingMember,
    bookingProductDive,
    bookingSessionParticipant,
    diveSession,
    diveSessionParticipant,
  } = bookingParticipantFull;

  const participant: BookingParticipantEditorParticipant = {
    bookingId,
    diver,
    bookingMember,
    bookingSessionId: bookingSession._id,
    diveSession: diveSession,
    bookingProduct: bookingProductDive,
    bookingSessionParticipant,
    clubParticipant: diveSessionParticipant,
    docResumes: bookingParticipantFull.docResumes,
    inquiryResponses: bookingParticipantFull.inquiryResponses,
  };
  return participant;
}

export function buildParticipantEditorCreateOpenInputState({
  bookingId,
  diver,
  bookingMember,
  bookingSessionId,
  diveSession,
  docResumes,
  inquiryResponses,
}: Pick<
  BookingParticipantEditorParticipant,
  | 'bookingId'
  | 'diver'
  | 'bookingMember'
  | 'bookingSessionId'
  | 'diveSession'
  | 'docResumes'
  | 'inquiryResponses'
>): BookingParticipantEditorParticipant {
  const participant: BookingParticipantEditorParticipant = {
    bookingId,
    diver,
    bookingMember,
    bookingSessionId,
    diveSession,
    docResumes,
    inquiryResponses,
  };
  return participant;
}

export type DiverBookingPageBookingCardLocalState = ReturnType<
  typeof useDiverBookingPageBookingCardLocalState
>;
