/* eslint-disable @typescript-eslint/no-unused-vars */
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import { UseFieldArrayReturn, useFieldArray, useWatch } from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanSwitchRHF,
  AppInputColorRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useAppCurrencyMain,
  useExportSheetSettingsOrientationOptions,
} from 'src/pages/_components/options';
import { DailyExportConfigFormModel } from '../_model';
import { DailyExportConfigDialogLocalState } from '../useDailyExportConfigDialogLocalState.hook';
import { DailyExportConfigOptionsPanelSheetContent } from './DailyExportConfigOptionsPanelSheetContentItem/DailyExportConfigOptionsPanelSheetContent';
export type DailyExportConfigOptionsPanelSheetOnClickAction =
  | 'move-down'
  | 'move-up'
  | 'delete';
export const DailyExportConfigOptionsPanelSheet = ({
  localState,
  dailyResumeIndex,
  sheetsFieldArray,
  sheetIndex,
  onClick,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetsFieldArray: UseFieldArrayReturn<
    DailyExportConfigFormModel,
    'dailyResumes.0.exportConfig.sheets',
    '_ref'
  >;
  sheetIndex: number;
  onClick: (action: DailyExportConfigOptionsPanelSheetOnClickAction) => any;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}` as const;

  const [contentTitle] = useWatch({
    control,
    name: [`${baseFormName}.content.title`],
  });

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  const contentItemsFieldArray = useFieldArray({
    control,
    name: `${baseFormName}.content.items`,
    keyName: '_ref',
  });

  const isMoveDownAllowed =
    sheetsFieldArray.fields.length > 1 &&
    sheetIndex + 1 < sheetsFieldArray.fields.length;
  const isMoveUpAllowed =
    sheetsFieldArray.fields.length > 1 && sheetIndex !== 0;

  const isDeleteAllowed = sheetsFieldArray.fields.length > 1;

  const orientationOptions = useExportSheetSettingsOrientationOptions();

  return (
    <div className={className}>
      <div className={clsx('grid gap-8')}>
        <h3 className="text-center sm:text-left text-xl font-extrabold text-gray-600 uppercase">
          Onglet N°{sheetIndex + 1} - {contentTitle}
        </h3>

        <div className="flex flex-col lg:flex-row-reverse gap-x-8 gap-y-4 justify-between flex-wrap">
          <div className="flex gap-8 justify-end items-start">
            {isDeleteAllowed && (
              <AppButton
                size="normal"
                color={'danger-outline-light'}
                icon={AppIconsAction.delete}
                type="button"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (
                    await confirmDialog.confirmPromise({
                      title: `Supprimer l'onglet "${contentTitle}"`,
                      message: 'Êtes-vous sûr de vouloir supprimer cet onglet?',
                      type: 'remove',
                    })
                  ) {
                    onClick('delete');
                  }
                }}
              >
                Supprimer cet onglet
              </AppButton>
            )}
            <div
              className={clsx(
                'h-full flex md:flex-col md:justify-center gap-2',
                sheetsFieldArray.fields.length === 1 && 'invisible',
              )}
            >
              <Tippy
                delay={[1000, 100]}
                placement="top"
                content={'Déplacer au dessus'}
              >
                <AppIconsAction.up
                  className={clsx(
                    'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
                    isMoveUpAllowed
                      ? 'hover:bg-gray-600 cursor-pointer'
                      : 'opacity-50 cursor-not-allowed',
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    isMoveUpAllowed && onClick('move-up');
                  }}
                />
              </Tippy>
              <Tippy
                delay={[1000, 100]}
                placement="top"
                content={'Déplacer en dessous'}
              >
                <AppIconsAction.down
                  className={clsx(
                    'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
                    isMoveDownAllowed
                      ? 'hover:bg-gray-600 cursor-pointer'
                      : 'opacity-50 cursor-not-allowed',
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    isMoveDownAllowed && onClick('move-down');
                  }}
                />
              </Tippy>
            </div>
          </div>
          <div className="grid gap-4">
            {/* <div className="w-full flex justify-between gap-x-8 gap-y-2 flex-wrap text-base text-app-primary font-medium uppercase">
          <div className="flex-grow">Onglet</div>
        </div> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
              <AppFormControlRHF_Deprecated
                label={'Titre de la feuille'}
                control={control}
                name={`${baseFormName}.content.title`}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="text" />
                )}
              />
              <AppFormControlRHF_Deprecated
                label={'Titre de l\'onglet'}
                control={control}
                name={`${baseFormName}.tab.name`}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="text" />
                )}
              />
              <AppFormControlRHF_Deprecated
                label={'Couleur de l\'onglet'}
                control={control}
                name={`${baseFormName}.tab.color`}
                renderComponent={(props) => <AppInputColorRHF {...props} />}
              />
            </div>
            <AppFormControlRHF_Deprecated
              label={'Description (non imprimé)'}
              control={control}
              name={`${baseFormName}.tab.comment`}
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
              <AppFormControlRHF_Deprecated
                label={'Orientation de la page'}
                control={control}
                name={`${baseFormName}.settings.orientation`}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    {...props}
                    theme="tailwind"
                    disableClearButton={true}
                    options={orientationOptions}
                  />
                )}
              />
              <AppFormControlRHF_Deprecated
                label={'Impression noir & blanc'}
                helpDescription="Décocher cette case pour imprimer le fond gris des cellules"
                control={control}
                name={`${baseFormName}.settings.blackAndWhite`}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF {...props} />
                )}
              />
              <AppFormControlRHF_Deprecated
                label={'Hauteur de ligne'}
                control={control}
                name={`${baseFormName}.settings.defaultHeight`}
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    placeholder="20"
                    fullWidth
                    type="number"
                  />
                )}
              />
              {isSuperAdmin && (
                <AppFormControlRHF_Deprecated
                  label={'Nombre de colonnes'}
                  control={control}
                  name={`${baseFormName}.settings.widthCellsCount`}
                  renderComponent={(props) => (
                    <AppInputRHF
                      {...props}
                      placeholder="36"
                      fullWidth
                      type="number"
                    />
                  )}
                />
              )}
            </div>
          </div>
        </div>
        <DailyExportConfigOptionsPanelSheetContent
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
        />
      </div>
    </div>
  );
};
