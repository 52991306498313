/* eslint-disable @typescript-eslint/no-unused-vars */
import { Transition } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../ClubSettingsInquiryEditPagePanel';
import { ClubSettingsInquiryEditPageFormQuestions } from './ClubSettingsInquiryEditPageFormQuestions';
import { AppInquiryFormModel } from './model';

export type ClubSettingsInquiryEditPageFormSectionOnClickAction =
  | 'move-down'
  | 'move-up'
  | 'delete'
  | 'clone';
export const ClubSettingsInquiryEditPageFormSection = ({
  form,
  fieldArray,
  field,
  sectionIndex,
  onClick,
  localState,
  className,
}: {
  form: UseFormReturn<AppInquiryFormModel>;
  fieldArray: UseFieldArrayReturn<AppInquiryFormModel, 'sections', 'sectionId'>;
  field: FieldArrayWithId<AppInquiryFormModel, 'sections', 'sectionId'>;
  sectionIndex: number;
  onClick: (
    action: ClubSettingsInquiryEditPageFormSectionOnClickAction,
  ) => void;
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  className?: string;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    getValues,
  } = form;

  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const { fields, append, prepend, remove, swap, move, insert } = fieldArray;

  const formAttributeBaseName = `sections.${sectionIndex}` as const;

  const isMoveDownAllowed =
    fieldArray.fields.length > 1 && sectionIndex + 1 < fieldArray.fields.length;
  const isMoveUpAllowed = fieldArray.fields.length > 1 && sectionIndex !== 0;

  const [color, sectionId, sortIndex, description, questions] = useWatch({
    control,
    name: [
      'settings.color',
      `${formAttributeBaseName}.sectionId`,
      `${formAttributeBaseName}.sortIndex`,
      `${formAttributeBaseName}.description`,
      `${formAttributeBaseName}.questions`,
    ],
  });

  const [showDetails, setShowDetails] = React.useState(true);

  return (
    <div className={clsx('app-card rounded-t-lg pb-4 grid gap-4', className)}>
      <div
        className="cursor-pointer flex gap-4 justify-between items-center rounded-t-lg text-white py-2 px-4"
        style={{
          backgroundColor: color,
        }}
        onClick={() => {
          setShowDetails(!showDetails);
        }}
      >
        <div className="text-lg font-medium uppercase">
          {description?.main?.title?.trim()?.length > 0
            ? description?.main?.title
            : `Section ${sectionIndex + 1}`}
        </div>
        {true && ( // localState.state.mode === 'create' // bug si on déplace une section sur un formulaire existant, tout disparait d'un coup!
          <div
            className={clsx(
              'flex gap-2',
              fieldArray.fields.length === 1 && 'invisible',
            )}
          >
            <Tippy
              delay={[1000, 100]}
              placement="top"
              content={'Déplacer au dessus'}
            >
              <AppIconsAction.up
                className={clsx(
                  'p-1 w-8 h-8 bg-gray-600 rounded-full text-white',
                  isMoveUpAllowed
                    ? 'hover:bg-gray-800 cursor-pointer'
                    : 'invisible',
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  isMoveUpAllowed && onClick('move-up');
                }}
              />
            </Tippy>
            <Tippy
              delay={[1000, 100]}
              placement="top"
              content={'Déplacer en dessous'}
            >
              <AppIconsAction.down
                className={clsx(
                  'p-1 w-8 h-8 bg-gray-600 rounded-full text-white',
                  isMoveDownAllowed
                    ? 'hover:bg-gray-800 cursor-pointer'
                    : 'invisible',
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  isMoveDownAllowed && onClick('move-down');
                }}
              />
            </Tippy>
          </div>
        )}
      </div>
      <div className={clsx('p-4 grid gap-4')}>
        <Transition
          show={showDetails}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div className="border-gray-600 pl-2 grid md:flex gap-2 lg:gap-4 items-start">
            <div className="flex-grow">
              <div className="w-full grid gap-2">
                <AppFormControlRHF
                  label="titre"
                  control={control}
                  name={`${formAttributeBaseName}.description.main.title`}
                  validation={{
                    rules: {
                      maxLength: 200,
                    },
                  }}
                  required={true}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth={true} type="text" />
                  )}
                />
                <AppFormControlRHF
                  label="Sous-titre"
                  control={control}
                  name={`${formAttributeBaseName}.description.main.subTitle`}
                  validation={{
                    rules: {
                      maxLength: 200,
                    },
                  }}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth={true} type="text" />
                  )}
                />
              </div>
            </div>
            <div className="flex gap-2 md:gap-6">
              <Tippy delay={[1000, 100]} placement="top" content={'Supliquer'}>
                <AppIconsAction.clone
                  className="p-1 w-8 h-8 bg-green-200 hover:bg-green-600 rounded-full text-white cursor-pointer"
                  onClick={() => {
                    onClick('clone');
                  }}
                />
              </Tippy>
              <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
                <AppIconsAction.delete
                  className="p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer"
                  onClick={() => {
                    onClick('delete');
                  }}
                />
              </Tippy>
            </div>
          </div>
        </Transition>
        {/* NOTE: on garde les questions en dehors de la transition, sinon elles disparaissent (bug react-hook-form?) */}
        <ClubSettingsInquiryEditPageFormQuestions
          className={clsx(!showDetails && 'hidden')}
          form={form}
          localState={localState}
          sectionIndex={sectionIndex}
        />
      </div>
    </div>
  );
};
