/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettings,
  DiveCenterPublicSettings,
} from '@mabadive/app-common-model';
import { diveGazFormatter } from '@mabadive/app-common-services';
import { default as React } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIconsDiveEquipment } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppFeatureListItem } from 'src/business/club/modules/_common/ui';
import { clubSettingsDiveCenterUrlBuilder } from 'src/pages/SE-settings/SE-01_dive-center/clubSettingsDiveCenterUrlBuilder.service';
import { useAppCurrencyMain } from 'src/pages/_components/options';

export const ClubSettingsCustomerViewCard = ({
  clubSettings,
  diveCenterPublicSettings,
}: {
  clubSettings: ClubSettings;
  diveCenterPublicSettings: DiveCenterPublicSettings;
}) => {
  const mainCurrency = useAppCurrencyMain();

  const customerSettings = clubSettings?.customer;

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <>
      <h2 className="py-2  text-left uppercase text-lg leading-6 font-medium text-gray-800">
        Client - coordonnées
      </h2>
      <ul className="relative grid gap-6 p-2 sm:gap-4 sm:px-4 font-bold text-gray-600">
        <AppFeatureListItem
          enabled={true}
          label="Champs toujours affichés (nom, prénom, email...)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.profilePhoto}
          label="Photo de profil"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.languageCode}
          label="Langue"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.homeAddress}
          label="Adresse postale"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.externalRef}
          label={`ID perso${
            customerSettings?.general?.externalRefLabel
              ? `: "${customerSettings?.general?.externalRefLabel}"`
              : ''
          }`}
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.identificationNumber}
          label="N° CNI ou Passeport"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.residentType}
          label="Résidence (résident ou vacancier)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.accomodation}
          label="Hébergement"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.pickup}
          label="Pickup"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.customerOrigin}
          label="Origine client (Internet, bouche à oreille...)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.sanitaryPass}
          label="Pass sanitaire (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.newsletter}
          label="Inscription à la newsletter (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.phoneNumber2}
          label="Second numéro de téléphone"
        />
        <AppFeatureListItem enabled={true} label="Date de naissance" />
        <AppFeatureListItem
          enabled={customerSettings?.general?.age}
          label="Âge"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.size}
          label="Taille (cm)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.weight}
          label="Poids (Kg)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.shoeSize}
          label="Pointure"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.isCustomer}
          label="Client non-plongeur"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.customField1}
          label={
            customerSettings?.general?.customField1
              ? `Champ personnalisé N°1: "${customerSettings?.general?.customField1Label}"`
              : 'Champ personnalisé N°1'
          }
        />
        {customerSettings?.general?.customField2 && (
          <AppFeatureListItem
            enabled={customerSettings?.general?.customField2}
            label={
              customerSettings?.general?.customField2
                ? `Champ personnalisé N°2: "${customerSettings?.general?.customField2Label}"`
                : 'Champ personnalisé N°2'
            }
          />
        )}
        {customerSettings?.general?.customField3 && (
          <AppFeatureListItem
            enabled={customerSettings?.general?.customField3}
            label={
              customerSettings?.general?.customField3
                ? `Champ personnalisé N°3: "${customerSettings?.general?.customField3Label}"`
                : 'Champ personnalisé N°3'
            }
          />
        )}
      </ul>
      <h2 className="py-2  text-left uppercase text-lg leading-6 font-medium text-gray-800">
        Client - adhérent
      </h2>
      <ul className="relative grid gap-6 p-2 sm:gap-4 sm:px-4 font-bold text-gray-600">
        <AppFeatureListItem
          enabled={customerSettings?.general?.isMember}
          label="Membre du club (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.memberRef}
          label="Numéro de membre"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.membershipDate}
          label="Date d'adhésion"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.isStudent}
          label="En formation club (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.general?.studentDescription}
          label="Détails formation club"
        />
      </ul>
      <h2 className="py-2  text-left uppercase text-lg leading-6 font-medium text-gray-800">
        Plongée
      </h2>
      <ul className="relative grid gap-6 p-2 sm:gap-4 sm:px-4 font-bold text-gray-600">
        <AppFeatureListItem
          enabled={customerSettings?.diving?.medicalCertificateDate}
          label="Certificat médical (date)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.medicalCertificateChecked}
          label="Certificat médical vérifié (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.medicalFormDate}
          label="Formulaire médical (date)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.medicalFormChecked}
          label="Formulaire médical vérifié (oui/non)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.insurance}
          label="Assurance (oui/non, nom/numéro, date d'expiration)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.safety}
          label="Niveau secourisme (RIFAP)"
        />
        <AppFeatureListItem
          enabled={customerSettings?.diving?.nitrox}
          label="Niveau nitrox (simple/confirmé)"
        />
        {diveCenterPublicSettings?.diving?.defaultGaz?.gazType && (
          <AppFeatureListItem
            enabled={true}
            isDiveCenterSpecificFeature={true}
            label={`Gaz par défaut: ${diveGazFormatter.formatGazType(
              diveCenterPublicSettings?.diving?.defaultGaz?.gazType,
              { format: 'long' },
            )}`}
          />
        )}
      </ul>
      <h2 className="py-2  text-left uppercase text-lg leading-6 font-medium text-gray-800">
        Réservation
      </h2>
      <ul className="relative grid gap-6 p-2 sm:gap-4 sm:px-4 font-bold text-gray-600">
        <AppFeatureListItem
          enabled={customerSettings?.booking?.externalRef}
          label={`ID perso${
            customerSettings?.booking?.externalRefLabel
              ? `: "${customerSettings?.booking?.externalRefLabel}"`
              : ''
          }`}
        />

        <AppFeatureListItem
          enabled={customerSettings?.booking?.agency}
          label="Agence"
        />
        <AppFeatureListItem
          enabled={customerSettings?.booking?.customField1}
          label={
            customerSettings?.booking?.customField1
              ? `Champ personnalisé N°1: "${customerSettings?.booking?.customField1Label}"`
              : 'Champ personnalisé N°1'
          }
        />
        {customerSettings?.booking?.customField2 && (
          <AppFeatureListItem
            enabled={customerSettings?.booking?.customField2}
            label={
              customerSettings?.booking?.customField2
                ? `Champ personnalisé N°2: "${customerSettings?.booking?.customField2Label}"`
                : 'Champ personnalisé N°2'
            }
          />
        )}
        {customerSettings?.booking?.customField3 && (
          <AppFeatureListItem
            enabled={customerSettings?.booking?.customField3}
            label={
              customerSettings?.booking?.customField3
                ? `Champ personnalisé N°3: "${customerSettings?.booking?.customField3Label}"`
                : 'Champ personnalisé N°3'
            }
          />
        )}
      </ul>
      {az?.edit?.settings?.equipment && (
        <div className="my-4">
          <h2 className="text-left uppercase text-lg leading-6 py-2 font-medium text-gray-800">
            Équipements
          </h2>
          <AppButton
            icon={AppIconsDiveEquipment.all}
            color={'gray-outline-light'}
            href={clubSettingsDiveCenterUrlBuilder.equipments.list()}
          >
            Voir la configuration des équipements
          </AppButton>
        </div>
      )}
    </>
  );
};
